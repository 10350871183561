$icon-font-url: '../../Fonts/Icomoon/';

@font-face {
    font-family: 'Icomoon';
    src:  url('#{$icon-font-url}icomoon.eot?tmhb7e');
    src:  url('#{$icon-font-url}icomoon.eot?tmhb7e#iefix') format('embedded-opentype'),
    url('#{$icon-font-url}icomoon.ttf?tmhb7e') format('truetype'),
    url('#{$icon-font-url}icomoon.woff?tmhb7e') format('woff'),
    url('#{$icon-font-url}icomoon.svg?tmhb7e#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="if-"], [class*=" if-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.if-sun:before,
.if-01d:before {
    content: "\e603";
}

.if-moon:before,
.if-01n:before{
    content: "\e604";
}

.if-cloudy:before,
.if-02d:before{
    content: "\e608";
}

.if-cloud:before,
.if-02n:before{
    content: "\e609";
}

.if-lines:before,
.if-50d:before,
.if-50n:before {
    content: "\e60d";
}

.if-cloud2:before,
.if-03d:before,
.if-03n:before {
    content: "\e60e";
}

.if-rainy:before,
.if-10d:before,
.if-10n:before {
    content: "\e611";
}

.if-rainy2:before,
.if-09d:before,
.if-09n:before {
    content: "\e612";
}

.if-snowy3:before,
.if-13d:before,
.if-13n:before {
    content: "\e617";
}

.if-cloudy2:before,
.if-04d:before,
.if-04n:before {
    content: "\e619";
}

.if-lightning3:before,
.if-11d:before,
.if-11n:before {
    content: "\e61b";
}
