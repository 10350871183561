
.infoteaser-btn {
    position: fixed;
    right: 10px;
    bottom: 70px;
    display: block;
    width: 48px;
    height: 48px;
    padding: 10px;
    background: $light;
    border: 0;
    border-radius: 24px;
    color: $primary;
    box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
    z-index:($zindex-menu) - 2;
    appearance: none;
    pointer-events: auto;

    @if($env == 'adm'){
        background: $white;
        padding: 7px;
        width: 70px;
        height: 70px;
        border-radius: 50px;
    }

    &:focus,
    &.focus {
        outline-color: $gray-400;

        @if($env == 'adm'){
            outline-color: $gray-200;
        }
    }

    > svg {
        width: 100%;
        height: 100%;

        @if($env == 'adm'){
            margin-top: 4px;
        }
    }

    @include media-breakpoint-up(lg) {
        width: 68px;
        height: 68px;
        padding: 14px;
        border-radius: 34px;
        bottom: 10px;

        @if($env == 'adm'){
            width: 90px;
            height: 90px;
            padding: 8px;
            border-radius: 50px;
            bottom: 25px;
            right: 25px;
        }
        @if($env == 'adm'){
            > svg {
                margin-top: 4px;
            }
        }
    }
}

.infoteaser-items-wrapper {
    position: fixed;
    display: flex;
    align-items: flex-end;
    top: 10px;
    right: 10px;
    left: 10px;
    bottom: 128px;
    z-index: ($zindex-menu) - 2;
    pointer-events: none;

    @if($env == 'adm'){
        bottom: 150px;
    }

    &.show-it {
        .infoteaser-inner {
            animation-name: infoIn;
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }

    &.hide-it {
        .infoteaser-inner {
            animation-name: infoOut;
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }


    .infoteaser-inner {
        position: relative;
        padding: 4rem 1.25rem 1.25rem;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
        background-color: $white;
        pointer-events: auto;
    }

    @include media-breakpoint-up(sm) {
        left: auto;
        width: 430px;

        @if($env == 'adm'){
            width: 475px;
        }
    }

    @include media-breakpoint-up(lg) {
        right: 40px;
        bottom: 98px;

        @if($env == 'adm'){
            bottom: 125px;
        }

        .infoteaser-inner {
            padding: 4rem 2.625rem 2.625rem;
        }
    }
}

.infoteaser-title {
    font-size: 2.188rem;

    @if($env == 'adm'){
        font-size: 1.563rem;

        @include media-breakpoint-up(sm) {
            font-size: 2.188rem;
        }
    }
}

@if($env == 'adm'){
    .infoteaser-text {
        font-weight: 300;
    }
}

.infoteaser-close {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    display: block;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;


    &:focus,
    &.focus {
        outline-color: $gray-400;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 12px;
        top: 0;
        height: 26px;
        width: 1px;
        background-color: $dark;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

@keyframes infoIn{
    0%{
        opacity: 0;
        transform: scale(0.9) translate3d(1.5rem, 0.5rem, 0);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}

@keyframes infoOut{
    0%{
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);

    }
    100%{
        opacity: 0;
        transform: scale(0.9) translate3d(1.5rem, 0.5rem, 0);
    }
}
