// Textpic and Textmedia

.textmedia,
.textpic {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1 * ($grid-gutter-width / 2);
    margin-right: -1 * ($grid-gutter-width / 2);
}

.textmedia-item,
.textpic-item {
    width: 100%;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
}

.textmedia-text,
.textpic-text {
    padding-top: 20px;

    > *:last-child {
        margin-bottom: 0;
    }
}

.textmedia-below,
.textpic-below,
.textmedia-right ,
.textpic-right{
    .textmedia-text,
    .textpic-text {
        margin-bottom: $spacer * 3;
    }
}

@include media-breakpoint-up(md) {
    .textmedia-right,
    .textmedia-left,
    .textpic-right,
    .textpic-left {
        .textmedia-item,
        .textpic-item {
            margin-bottom: 0;
            width: 50%;
        }
    }
}

@if($env == 'adm') {
    .textmedia-above .gallery-item-size-1,
    .textmedia-below .gallery-item-size-1 {
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(lg) {
            width: 83.33333%;
        }

        @include media-breakpoint-up(xl) {
            width: 66.66667%;
        }
    }

    @include media-breakpoint-up(md) {
        .textpic-left.pic-25,
        .textpic-right.pic-25 {
            .textpic-gallery {
                width: 25%;
            }

            .textpic-text {
                width: 75%;
            }
        }

        .textpic-left.pic-75,
        .textpic-right.pic-75 {
            .textpic-gallery {
                width: 75%;
            }

            .textpic-text {
                width: 25%;
            }
        }

        .textpic-left.pic-50,
        .textpic-right.pic-50 {
            .textpic-gallery {
                width: 50%;
            }

            .textpic-text {
                width: 50%;
            }
        }
    }

    @include media-breakpoint-down(md)  {
        .textpic-right {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

