// Selftest

.frame-type-wvier_selftest {
    .selftest-wrapper {
        max-width: 920px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .selftest-table {
            width: 100%;

            .yes, .no,
            .input-yes, .input-no {
                text-transform: uppercase;
                font-size: 14px;
                text-align: center;
                width: 50px;
            }

            .selftest-question {
                border-bottom: 2px solid $gray-400;

                .question {
                    display: flex;
                    padding: 20px 20px 20px 0;

                    label {
                        color: $primary;
                        font-size: 20px;
                        font-family: $font-family-myriadpro;
                        margin: 0 10px 0 0;
                    }

                    p {
                        margin: 0;
                    }
                }

                .input-yes, .input-no {
                    input[type=radio] {
                        display: none;

                        &:hover {
                            &+label {
                                span {
                                    background-color: $gray-400;
                                    cursor: pointer;
                                }
                            }
                        }

                        &:checked {
                            &+label {
                                span {
                                    background-color: $primary;
                                }
                            }
                        }
                    }

                    label {
                        display: inline-block;
                        height: 30px;
                        width: 30px;

                        .radiobtn {
                            display: inline-block;
                            height: 30px;
                            width: 30px;
                            background-color: $gray-300;
                            border-radius: 50px;
                        }
                    }
                }
            }
        }

        button {
            margin-top: 50px;
        }
    }

    .modal {
        display: none;
        overflow: hidden;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5050;
        outline: 0;

        @include media-breakpoint-up(lg) {
            margin-top: 100px;
        }

        .modal-content {
            position: relative;
            background-color: #fff;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            border: none;

            .modal-header {
                padding: 15px;
                border: none;

                .close {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    width: 15px;
                    height: 15px;

                    &:before,
                    &:after {
                        position: absolute;
                        left: 15px;
                        content: ' ';
                        height: 20px;
                        width: 2px;
                        top: 6px;
                        background-color: #333;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }

            .modal-body {
                text-align: center;
                padding: 30px 50px 50px;

                .headline_negative {
                    color: red;
                }

                .headline_success {
                    color: green;
                }
            }
        }
    }
}
