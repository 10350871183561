// Gallery

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;

    &.gallery-slider {
        overflow: hidden;

        .owl-stage {
            display: flex;

            .owl-item {
                display: flex;
                align-items: center;

                .gallery-item {
                    margin: 0 auto;

                    @include media-breakpoint-up(sm) {
                        &.gallery-item-size-2,
                        &.gallery-item-size-3,
                        &.gallery-item-size-4 {
                            width: 60%;
                        }
                    }

                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .owl-nav {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            button {
                border: none;
                background: none;
                width: 40px;
                height: 40px;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 100%;
                    height: 100%;
                }

                &.owl-next {
                    transform: rotate(180deg);
                }

                &:hover {
                    svg {
                        fill: $darkblue;
                    }
                }

                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }
    }

}
.gallery-item {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    img,
    picture {
        /*width: 100%;*/
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }

    figure {
        margin-bottom: $grid-gutter-width;
    }
}



// Gallery Item Sizes

.gallery-item-size-1 {
    width: 100%;
}
.gallery-item-size-2 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(100% / 2);
    }
}
.gallery-item-size-3 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(99.9% / 3);
    }
}

.gallery-item-size-4 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(100% / 4);
    }
}
