/* autoprefixer grid: autoplace */
@font-face {
  font-family: 'Icomoon';
  src: url("../../Fonts/Icomoon/icomoon.eot?tmhb7e");
  src: url("../../Fonts/Icomoon/icomoon.eot?tmhb7e#iefix") format("embedded-opentype"), url("../../Fonts/Icomoon/icomoon.ttf?tmhb7e") format("truetype"), url("../../Fonts/Icomoon/icomoon.woff?tmhb7e") format("woff"), url("../../Fonts/Icomoon/icomoon.svg?tmhb7e#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="if-"], [class*=" if-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.if-sun:before,
.if-01d:before {
  content: "\e603";
}

.if-moon:before,
.if-01n:before {
  content: "\e604";
}

.if-cloudy:before,
.if-02d:before {
  content: "\e608";
}

.if-cloud:before,
.if-02n:before {
  content: "\e609";
}

.if-lines:before,
.if-50d:before,
.if-50n:before {
  content: "\e60d";
}

.if-cloud2:before,
.if-03d:before,
.if-03n:before {
  content: "\e60e";
}

.if-rainy:before,
.if-10d:before,
.if-10n:before {
  content: "\e611";
}

.if-rainy2:before,
.if-09d:before,
.if-09n:before {
  content: "\e612";
}

.if-snowy3:before,
.if-13d:before,
.if-13n:before {
  content: "\e617";
}

.if-cloudy2:before,
.if-04d:before,
.if-04n:before {
  content: "\e619";
}

.if-lightning3:before,
.if-11d:before,
.if-11n:before {
  content: "\e61b";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #7c7c7c;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #333;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #7c7c7c;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #7c7c7c;
}

.table tbody + tbody {
  border-top: 2px solid #7c7c7c;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #7c7c7c;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #7c7c7c;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #7c7c7c;
  background-color: #f1f1f1;
  border-color: #7c7c7c;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1419.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1420px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f1f1f1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #2b2b2b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:first-child {
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}

.dropdown-item:last-child {
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e1e1e;
  text-decoration: none;
  background-color: #f6f6f6;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #002454;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #2b2b2b;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #7c7c7c;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #333;
  background-color: #fff;
  border-color: #005bd4;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 36, 84, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f1f1f1;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #333;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #647b33;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(100, 123, 51, 0.9);
  border-radius: 0;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #647b33;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #647b33;
  box-shadow: 0 0 0 0.2rem rgba(100, 123, 51, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #647b33;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #647b33;
  box-shadow: 0 0 0 0.2rem rgba(100, 123, 51, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #647b33;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #647b33;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #647b33;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #819f42;
  background-color: #819f42;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(100, 123, 51, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #647b33;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #647b33;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #647b33;
  box-shadow: 0 0 0 0.2rem rgba(100, 123, 51, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1rem 1.1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e9e9e9;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f1f1f1 #f1f1f1 #e9e9e9;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #7c7c7c;
  background-color: #fff;
  border-color: #e9e9e9 #e9e9e9 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #002454;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f1f1f1;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #7c7c7c;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #7c7c7c;
  text-decoration: none;
  background-color: #f6f6f6;
}

.list-group-item-action:active {
  color: #333;
  background-color: #f1f1f1;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #002454;
  border-color: #002454;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1420px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #7c7c7c;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #7c7c7c;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1420px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9e9e9 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #7c7c7c;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #7c7c7c;
  }
}

@media (min-width: 992px) {
  .menu-overlay .menu-bg-image {
    position: absolute;
    bottom: -20%;
    right: 10%;
    opacity: 0.15;
    width: 650px;
    height: 650px;
    z-index: -1;
  }
}

@media (min-width: 1420px) {
  .menu-overlay {
    background-position: 80% bottom;
  }
}

.menu-overlay .menu-inner {
  position: relative;
  height: 100%;
  padding-bottom: 60px;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (min-width: 992px) {
  .menu-overlay .menu-inner {
    padding-bottom: 0;
    padding-left: 90px;
    padding-right: 360px;
  }
}

@media (min-width: 1420px) {
  .menu-overlay .menu-inner {
    padding-right: 460px;
  }
}

@media (max-width: 991.98px) {
  .menu-overlay .navbar-nav {
    padding-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .menu-overlay .navbar-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 3rem auto 0;
    max-width: 992px;
  }
  .menu-overlay .navbar-nav > .nav-item {
    flex-basis: calc(100% / 2);
  }
}

@media (min-width: 1250px) {
  .menu-overlay .navbar-nav > .nav-item {
    flex-basis: calc(99.9% / 3);
    max-width: 33.3%;
  }
}

@media (min-width: 1420px) {
  .menu-overlay .navbar-nav > .nav-item {
    flex-basis: calc(99.9% / 3);
    max-width: 33.3%;
  }
}

@media (min-width: 1700px) {
  .menu-overlay .navbar-nav {
    max-width: 1100px;
  }
}

.menu-overlay .first-level {
  position: relative;
}

.menu-overlay .first-level > .nav-link {
  font-family: "myriad-pro", sans-serif;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .menu-overlay .first-level > .nav-link {
    font-size: 1.75rem;
    border-bottom: 1px solid #f6f6f6;
    white-space: normal;
    font-size: 1.5rem;
    border-bottom: 0;
  }
  .menu-overlay .first-level > .nav-link > .svg-icon {
    font-size: 1.25rem;
    margin-left: 0.5rem;
    transform: rotate(-90deg);
  }
  .menu-overlay .first-level.dropdown.show .nav-link .svg-icon {
    transform: rotate(90deg);
  }
}

@media (min-width: 992px) {
  .menu-overlay .first-level {
    margin-top: 2rem;
  }
  .menu-overlay .first-level > .nav-link {
    font-family: "myriad-pro", sans-serif;
    font-size: 1.7rem;
    font-family: "myriad-pro", sans-serif;
    font-size: 1.375rem;
    white-space: initial;
  }
  .menu-overlay .first-level > .nav-link > .svg-icon {
    display: none;
  }
}

@media (min-width: 1420px) {
  .menu-overlay .first-level > .nav-link {
    font-size: 2.2rem;
    font-size: 1.375rem;
  }
}

@media (min-width: 1700px) {
  .menu-overlay .first-level > .nav-link {
    font-size: 1.813rem;
  }
}

.menu-overlay .second-level > .nav-link {
  font-size: 1.125rem;
  font-weight: 300;
  border-bottom: 1px solid #f6f6f6;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

@media (min-width: 992px) {
  .menu-overlay .second-level > .nav-link {
    margin-left: 0;
    margin-right: 0;
    border-bottom: 0;
  }
}

@media (max-width: 991.98px) {
  .menu-overlay .second-level > .nav-link {
    padding-left: 2.2rem;
    padding-left: 0.7rem;
  }
}

.menu-overlay .dropdown-menu .nav-item:last-child a {
  border-bottom: 0;
}

.menu-overlay .dropdown-menu {
  position: static;
  float: none;
  width: 100%;
  margin: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #f6f6f6;
  background-color: transparent;
  border-bottom: 0;
}

@media (min-width: 992px) {
  .menu-overlay .dropdown-menu {
    display: block !important;
    border: 0;
  }
}

.navbar-nav {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.nav-link {
  position: relative;
  display: block;
  line-height: 1;
  transition: all 0.2s;
  color: #333;
  color: #002454;
  text-decoration: none !important;
}

.nav-link:hover {
  color: #0d0d0d;
  color: #002454 !important;
  background-color: #FFCC00;
  text-decoration: underline !important;
}

@media (min-width: 992px) {
  .nav-link:hover {
    background-color: transparent;
  }
}

.nav-link:focus {
  outline: 1px dotted;
  outline-offset: -2px;
}

@media (max-width: 991.98px) {
  .nav-link {
    font-family: "myriad-pro", sans-serif;
    padding: 1rem 1.1rem;
  }
  .nav-link.active {
    background-color: #002454;
    color: #fff;
    background-color: #FFCC00;
    color: #002454;
  }
}

@media (min-width: 992px) {
  .nav-link {
    padding: 0.375rem 1.1rem;
  }
  .nav-link.active, .nav-link:hover {
    color: #002454;
    text-decoration: underline;
  }
}

.menu-open {
  overflow: hidden;
}

.scrolled .navbar-toggler .navbar-toggler-text,
.menu-open .navbar-toggler .navbar-toggler-text {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility 0s 0.2s, opacity 0.2s;
}

.btn, .cookie-panel .c-p-controls button {
  display: inline-block;
  font-family: "myriad-pro", sans-serif;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.btn:hover, .cookie-panel .c-p-controls button:hover {
  text-decoration: none;
}

.btn:focus, .cookie-panel .c-p-controls button:focus, .btn.focus, .cookie-panel .c-p-controls button.focus {
  outline: 1px dotted;
  outline-offset: 2px;
}

.btn.disabled, .cookie-panel .c-p-controls button.disabled, .btn:disabled, .cookie-panel .c-p-controls button:disabled {
  opacity: 0.65;
  pointer-events: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-default, .cookie-panel .c-p-controls .c-p-btn--save-all {
  color: #fff;
  background-color: #002454;
  border-color: #002454;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.625rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-transform: none;
  border-radius: 100px;
}

.btn-default:hover, .cookie-panel .c-p-controls .c-p-btn--save-all:hover {
  color: #333;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
  color: #002454;
  background-color: #fff;
  border-color: #002454;
}

.btn-default:focus, .cookie-panel .c-p-controls .c-p-btn--save-all:focus, .btn-default.focus, .cookie-panel .c-p-controls .focus.c-p-btn--save-all {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 36, 84, 0.2);
}

.btn-default:not(:disabled):not(.disabled):active, .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled).active {
  color: #fff;
  background: #001735;
}

@media (min-width: 992px) {
  .btn-default, .cookie-panel .c-p-controls .c-p-btn--save-all {
    font-size: 1.125rem;
    padding: 0.625rem 1rem;
  }
}

.btn-secondary, .cookie-panel .c-p-controls .c-p-btn--save {
  color: #002454;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.625rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  background-color: #FFCC00;
  border-color: #FFCC00;
  text-transform: none;
  border-radius: 100px;
}

.btn-secondary:hover, .cookie-panel .c-p-controls .c-p-btn--save:hover {
  color: #fff;
  background-color: #002454;
  border-color: #002454;
  color: #002454;
  background-color: #e0b400;
  border-color: #e0b400;
}

.btn-secondary:focus, .cookie-panel .c-p-controls .c-p-btn--save:focus, .btn-secondary.focus, .cookie-panel .c-p-controls .focus.c-p-btn--save {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 36, 84, 0.2);
}

.btn-secondary:not(:disabled):not(.disabled):active, .cookie-panel .c-p-controls .c-p-btn--save:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .cookie-panel .c-p-controls .c-p-btn--save:not(:disabled):not(.disabled).active {
  color: #fff;
  background: #001735;
}

@media (min-width: 992px) {
  .btn-secondary, .cookie-panel .c-p-controls .c-p-btn--save {
    font-size: 1.125rem;
    padding: 0.625rem 1rem;
  }
}

.btn-white {
  color: #333;
  background-color: rgba(51, 51, 51, 0);
  border-color: #333;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.625rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.btn-white:hover {
  color: #333;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.btn-white:focus, .btn-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2);
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active {
  background: rgba(51, 51, 51, 0.5);
}

.btn-sm {
  position: relative;
  color: #333;
  font-size: 0.938rem;
  text-transform: uppercase;
  padding: 0.5rem 0.5rem;
  background-color: #FFCC00;
  border-color: #FFCC00;
  text-transform: none;
  border-radius: 100px;
  font-size: 1rem;
  padding: 0.625rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (min-width: 992px) {
  .btn-sm {
    font-size: 1.125rem;
    padding: 0.625rem 1rem;
  }
}

.btn-sm:focus, .btn-sm.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.2);
}

.btn-sm:focus::before, .btn-sm:focus::after, .btn-sm.focus::before, .btn-sm.focus::after {
  width: 100%;
  height: 100%;
  border-color: #333;
}

.btn-sm:not(:disabled):not(.disabled):active, .btn-sm:not(:disabled):not(.disabled).active {
  background: #e2e2e2;
}

.btn-sm::before, .btn-sm::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  background: transparent;
  content: none;
}

.btn-sm::before {
  bottom: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid #333;
  border-right: 1px solid #333;
  transition-property: height;
  transition-delay: 0.4s;
  transition-duration: 0.2s;
}

.btn-sm::after {
  top: 0;
  right: 0;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  transition-property: height, width, border-color;
  transition-delay: 0s, 0.2s, 0.4s;
  transition-duration: 0.2s, 0.2s, 0s;
}

.btn-sm:hover {
  color: #002454;
  background-color: #e0b400;
  border-color: #e0b400;
}

.btn-sm:hover::before {
  height: 100%;
  transition-delay: 0s;
}

.btn-sm:hover::after {
  width: 100%;
  height: 100%;
  border-top-color: #333;
  border-left-color: #333;
  transition-property: border-color, width, height;
  transition-delay: 0.2s, 0.2s, 0.4s;
  transition-duration: 0s, 0.2s, 0.2s;
}

.btn-yellow {
  position: relative;
  color: #002454;
  font-size: 0.938rem;
  text-transform: uppercase;
  padding: 0.5rem 0.5rem;
  background-color: #FFCC00;
  border-color: #FFCC00;
  text-transform: none;
  border-radius: 100px;
  font-size: 1rem;
  padding: 0.625rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (min-width: 992px) {
  .btn-yellow {
    font-size: 1.125rem;
    padding: 0.625rem 1rem;
  }
}

.btn-yellow:focus, .btn-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 204, 0, 0.2);
}

.btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active {
  background: #e0b400;
}

.btn-yellow:hover {
  color: #002454;
  background-color: #e0b400;
  border-color: #e0b400;
}

.frame-background-dark .btn-default, .frame-background-dark .cookie-panel .c-p-controls .c-p-btn--save-all, .cookie-panel .c-p-controls .frame-background-dark .c-p-btn--save-all, .frame-background-primary .btn-default, .frame-background-primary .cookie-panel .c-p-controls .c-p-btn--save-all, .cookie-panel .c-p-controls .frame-background-primary .c-p-btn--save-all,
.bg-dark .btn-default,
.bg-dark .cookie-panel .c-p-controls .c-p-btn--save-all,
.cookie-panel .c-p-controls .bg-dark .c-p-btn--save-all,
.bg-primary .btn-default,
.bg-primary .cookie-panel .c-p-controls .c-p-btn--save-all,
.cookie-panel .c-p-controls .bg-primary .c-p-btn--save-all {
  background-color: rgba(51, 51, 51, 0);
  border-color: #fff;
}

.frame-background-dark .btn-default:hover, .frame-background-dark .cookie-panel .c-p-controls .c-p-btn--save-all:hover, .cookie-panel .c-p-controls .frame-background-dark .c-p-btn--save-all:hover, .frame-background-primary .btn-default:hover, .frame-background-primary .cookie-panel .c-p-controls .c-p-btn--save-all:hover, .cookie-panel .c-p-controls .frame-background-primary .c-p-btn--save-all:hover,
.bg-dark .btn-default:hover,
.bg-dark .cookie-panel .c-p-controls .c-p-btn--save-all:hover,
.cookie-panel .c-p-controls .bg-dark .c-p-btn--save-all:hover,
.bg-primary .btn-default:hover,
.bg-primary .cookie-panel .c-p-controls .c-p-btn--save-all:hover,
.cookie-panel .c-p-controls .bg-primary .c-p-btn--save-all:hover {
  color: #333;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.frame-background-dark .btn-default:focus, .frame-background-dark .cookie-panel .c-p-controls .c-p-btn--save-all:focus, .cookie-panel .c-p-controls .frame-background-dark .c-p-btn--save-all:focus, .frame-background-dark .btn-default.focus, .frame-background-dark .cookie-panel .c-p-controls .focus.c-p-btn--save-all, .cookie-panel .c-p-controls .frame-background-dark .focus.c-p-btn--save-all, .frame-background-primary .btn-default:focus, .frame-background-primary .cookie-panel .c-p-controls .c-p-btn--save-all:focus, .cookie-panel .c-p-controls .frame-background-primary .c-p-btn--save-all:focus, .frame-background-primary .btn-default.focus, .frame-background-primary .cookie-panel .c-p-controls .focus.c-p-btn--save-all, .cookie-panel .c-p-controls .frame-background-primary .focus.c-p-btn--save-all,
.bg-dark .btn-default:focus,
.bg-dark .cookie-panel .c-p-controls .c-p-btn--save-all:focus,
.cookie-panel .c-p-controls .bg-dark .c-p-btn--save-all:focus,
.bg-dark .btn-default.focus,
.bg-dark .cookie-panel .c-p-controls .focus.c-p-btn--save-all,
.cookie-panel .c-p-controls .bg-dark .focus.c-p-btn--save-all,
.bg-primary .btn-default:focus,
.bg-primary .cookie-panel .c-p-controls .c-p-btn--save-all:focus,
.cookie-panel .c-p-controls .bg-primary .c-p-btn--save-all:focus,
.bg-primary .btn-default.focus,
.bg-primary .cookie-panel .c-p-controls .focus.c-p-btn--save-all,
.cookie-panel .c-p-controls .bg-primary .focus.c-p-btn--save-all {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2);
}

.frame-background-dark .btn-default:not(:disabled):not(.disabled):active, .frame-background-dark .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled):active, .cookie-panel .c-p-controls .frame-background-dark .c-p-btn--save-all:not(:disabled):not(.disabled):active, .frame-background-dark .btn-default:not(:disabled):not(.disabled).active, .frame-background-dark .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled).active, .cookie-panel .c-p-controls .frame-background-dark .c-p-btn--save-all:not(:disabled):not(.disabled).active, .frame-background-primary .btn-default:not(:disabled):not(.disabled):active, .frame-background-primary .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled):active, .cookie-panel .c-p-controls .frame-background-primary .c-p-btn--save-all:not(:disabled):not(.disabled):active, .frame-background-primary .btn-default:not(:disabled):not(.disabled).active, .frame-background-primary .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled).active, .cookie-panel .c-p-controls .frame-background-primary .c-p-btn--save-all:not(:disabled):not(.disabled).active,
.bg-dark .btn-default:not(:disabled):not(.disabled):active,
.bg-dark .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled):active,
.cookie-panel .c-p-controls .bg-dark .c-p-btn--save-all:not(:disabled):not(.disabled):active,
.bg-dark .btn-default:not(:disabled):not(.disabled).active,
.bg-dark .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled).active,
.cookie-panel .c-p-controls .bg-dark .c-p-btn--save-all:not(:disabled):not(.disabled).active,
.bg-primary .btn-default:not(:disabled):not(.disabled):active,
.bg-primary .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled):active,
.cookie-panel .c-p-controls .bg-primary .c-p-btn--save-all:not(:disabled):not(.disabled):active,
.bg-primary .btn-default:not(:disabled):not(.disabled).active,
.bg-primary .cookie-panel .c-p-controls .c-p-btn--save-all:not(:disabled):not(.disabled).active,
.cookie-panel .c-p-controls .bg-primary .c-p-btn--save-all:not(:disabled):not(.disabled).active {
  background: rgba(51, 51, 51, 0.5);
}

.frame-background-dark .btn-sm, .frame-background-primary .btn-sm,
.bg-dark .btn-sm,
.bg-primary .btn-sm {
  color: #fff;
}

.frame-background-dark .btn-sm:focus, .frame-background-dark .btn-sm.focus, .frame-background-primary .btn-sm:focus, .frame-background-primary .btn-sm.focus,
.bg-dark .btn-sm:focus,
.bg-dark .btn-sm.focus,
.bg-primary .btn-sm:focus,
.bg-primary .btn-sm.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2);
}

.frame-background-dark .btn-sm:focus::before, .frame-background-dark .btn-sm:focus::after, .frame-background-dark .btn-sm.focus::before, .frame-background-dark .btn-sm.focus::after, .frame-background-primary .btn-sm:focus::before, .frame-background-primary .btn-sm:focus::after, .frame-background-primary .btn-sm.focus::before, .frame-background-primary .btn-sm.focus::after,
.bg-dark .btn-sm:focus::before,
.bg-dark .btn-sm:focus::after,
.bg-dark .btn-sm.focus::before,
.bg-dark .btn-sm.focus::after,
.bg-primary .btn-sm:focus::before,
.bg-primary .btn-sm:focus::after,
.bg-primary .btn-sm.focus::before,
.bg-primary .btn-sm.focus::after {
  border-color: #fff;
}

.frame-background-dark .btn-sm:not(:disabled):not(.disabled):active, .frame-background-dark .btn-sm:not(:disabled):not(.disabled).active, .frame-background-primary .btn-sm:not(:disabled):not(.disabled):active, .frame-background-primary .btn-sm:not(:disabled):not(.disabled).active,
.bg-dark .btn-sm:not(:disabled):not(.disabled):active,
.bg-dark .btn-sm:not(:disabled):not(.disabled).active,
.bg-primary .btn-sm:not(:disabled):not(.disabled):active,
.bg-primary .btn-sm:not(:disabled):not(.disabled).active {
  background: rgba(51, 51, 51, 0.5);
}

.frame-background-dark .btn-sm::before, .frame-background-primary .btn-sm::before,
.bg-dark .btn-sm::before,
.bg-primary .btn-sm::before {
  border-bottom-color: #fff;
  border-right-color: #fff;
}

.frame-background-dark .btn-sm:hover::after, .frame-background-primary .btn-sm:hover::after,
.bg-dark .btn-sm:hover::after,
.bg-primary .btn-sm:hover::after {
  border-top-color: #fff;
  border-left-color: #fff;
}

.btn-link {
  color: #7c7c7c;
  text-decoration: none;
  border: 0;
}

.btn-link:hover {
  color: #565656;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1420px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1420px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1420px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1420px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #333 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* cookieconsent styling */
.cc-window {
  position: fixed;
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s ease;
  font-size: 1rem;
  z-index: 99999;
  background-color: #f1f1f1;
  color: #343a40;
  padding: 1rem;
  border: 1px solid #e9e9e9;
  /* Position */
  width: 100%;
  max-width: 375px;
  /* Top Center */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  /* Bottom left */
  /* bottom: 1rem;
    left: 1rem;*/
}

.cc-window.cc-invisible {
  opacity: 0;
}

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
}

.cc-revoke {
  display: none;
  visibility: hidden;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: 0.8;
  display: inline-block;
  text-decoration: underline;
}

.cc-link:hover {
  opacity: 1;
}

.cc-link:focus {
  outline: 1px dotted;
}

.cc-link:active, .cc-link:visited {
  color: initial;
}

.cc-dismiss {
  background-color: #002454 !important;
}

.cc-btn,
.db--overlay-submit {
  display: block;
  padding: 0.4rem 0.8rem;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  border-color: #002454;
  background-color: #002454;
}

.cc-btn:hover, .cc-btn:focus,
.db--overlay-submit:hover,
.db--overlay-submit:focus {
  color: #fff;
  background-color: #001530 !important;
  border-color: #001530;
  text-decoration: none;
}

.cc-btn:focus,
.db--overlay-submit:focus {
  outline: 1px dotted;
}

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
}

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
  background-color: transparent !important;
  text-decoration: underline;
}

.cc-highlight .cc-btn:last-child {
  font-size: 1.25rem;
}

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;
  line-height: 0.75;
}

.cc-close:focus,
.cc-close:hover {
  opacity: 1;
}

.cc-message {
  display: block;
  padding: 0 0.5rem;
}

.cc-compliance {
  display: flex;
  flex-wrap: wrap;
}

.cc-compliance .cc-btn {
  flex: 0 0 100%;
  max-width: 100%;
}

.cc-compliance .cc-btn + .cc-btn {
  margin-top: 0.5rem;
}

@media print {
  .cc-revoke,
  .cc-window {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

@media screen and (max-width: 419px) and (orientation: portrait), screen and (max-width: 767px) and (orientation: landscape) {
  .cc-window {
    top: 0;
    left: 0;
    right: 0;
    bottom: unset;
    transform: translateX(0);
    max-width: none;
  }
}

.dp--cookie-check {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}

.dp--cookie-check label {
  padding: 5px;
  display: block;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 0 0 50%;
  max-width: 50%;
}

.dp--iframe {
  display: none;
}

.dp--iframe.dp--loaded {
  display: block;
}

.dp--overlay {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f1f1f1;
  color: #343a40;
  border: 1px solid #e9e9e9;
}

.embed-responsive-16by9 .dp--overlay {
  margin-top: -56.25%;
}

.dp--overlay-header {
  font-weight: bold;
  font-size: 1rem;
}

.dp--overlay-description {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.dp--overlay-button {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.db--overlay-submit {
  font-size: 1rem;
  display: inline-block;
}

.dp--iframe.dp--loaded + .dp--overlay {
  display: none;
}

.cookie-panel h2, .cookie-panel h3, .cookie-panel h4, .cookie-panel h5 {
  font-family: "myriad-pro", sans-serif;
}

.cookie-panel h2 {
  padding-top: 1.5rem;
}

@media (max-width: 767.98px) {
  .cookie-panel h2 {
    padding-top: 3rem;
  }
}

.cookie-panel h3 {
  font-size: 1.688rem;
  line-height: 1.2;
}

.cookie-panel .cookie-panel__close {
  top: 30px;
  right: 30px;
}

.cookie-panel .cookie-panel__close span {
  background-color: #002454;
}

.frame {
  position: relative;
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 4.063rem;
  margin-bottom: 4.063rem;
}

@media (min-width: 992px) {
  .frame {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 1420px) {
  .frame {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
}

.frame > *:last-child {
  margin-bottom: 0;
}

.frame-inner > *:last-child {
  margin-bottom: 0;
}

.frame-ruler-before {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.frame-ruler-before .frame-inner {
  margin-top: 1rem;
}

.frame-ruler-after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.frame-ruler-after .frame-inner {
  margin-bottom: 1rem;
}

.frame-background-primary,
.bg-primary {
  color: #fff;
  background-color: #002454;
}

.frame-background-primary svg,
.bg-primary svg {
  fill: #fff;
}

.frame-background-primary a:not(.btn),
.bg-primary a:not(.btn) {
  color: #fff;
}

.frame-background-primary a:not(.btn):focus, .frame-background-primary a:not(.btn):hover,
.bg-primary a:not(.btn):focus,
.bg-primary a:not(.btn):hover {
  color: #d9d9d9;
}

.frame-background-primary {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4.063rem;
  padding-bottom: 4.063rem;
}

@media (min-width: 992px) {
  .frame-background-primary {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (min-width: 1420px) {
  .frame-background-primary {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.frame-background-light,
.bg-light {
  color: #2b2b2b;
  background-color: #f1f1f1;
}

.frame-background-light svg,
.bg-light svg {
  fill: #2b2b2b;
}

.frame-background-light a:not(.btn),
.bg-light a:not(.btn) {
  color: #7c7c7c;
}

.frame-background-light a:not(.btn):focus, .frame-background-light a:not(.btn):hover,
.bg-light a:not(.btn):focus,
.bg-light a:not(.btn):hover {
  color: #565656;
}

.frame-background-light {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4.063rem;
  padding-bottom: 4.063rem;
}

@media (min-width: 992px) {
  .frame-background-light {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (min-width: 1420px) {
  .frame-background-light {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.frame-background-dark,
.bg-dark {
  color: #fff;
  background-color: #333;
}

.frame-background-dark svg,
.bg-dark svg {
  fill: #fff;
}

.frame-background-dark a:not(.btn),
.bg-dark a:not(.btn) {
  color: #fff;
}

.frame-background-dark a:not(.btn):focus, .frame-background-dark a:not(.btn):hover,
.bg-dark a:not(.btn):focus,
.bg-dark a:not(.btn):hover {
  color: #d9d9d9;
}

.frame-background-dark {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4.063rem;
  padding-bottom: 4.063rem;
}

@media (min-width: 992px) {
  .frame-background-dark {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (min-width: 1420px) {
  .frame-background-dark {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.frame-background-hatched,
.bg-hatched {
  color: #2b2b2b;
  background-color: #f1f1f1;
}

.frame-background-hatched svg,
.bg-hatched svg {
  fill: #2b2b2b;
}

.frame-background-hatched a:not(.btn),
.bg-hatched a:not(.btn) {
  color: #7c7c7c;
}

.frame-background-hatched a:not(.btn):focus, .frame-background-hatched a:not(.btn):hover,
.bg-hatched a:not(.btn):focus,
.bg-hatched a:not(.btn):hover {
  color: #565656;
}

.frame-background-hatched {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4.063rem;
  padding-bottom: 4.063rem;
}

@media (min-width: 992px) {
  .frame-background-hatched {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (min-width: 1420px) {
  .frame-background-hatched {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.frame-background-hatched {
  background: #f1f1f1 url("../../Images/hatched.jpg") repeat;
}

.frame-space-before-large.frame-background-none {
  margin-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-none {
    margin-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-none {
    margin-top: 11.25rem;
  }
}

.frame-space-before-large.frame-background-primary {
  padding-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-primary {
    padding-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-primary {
    padding-top: 11.25rem;
  }
}

.frame-space-before-large.frame-background-light {
  padding-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-light {
    padding-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-light {
    padding-top: 11.25rem;
  }
}

.frame-space-before-large.frame-background-dark {
  padding-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-dark {
    padding-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-dark {
    padding-top: 11.25rem;
  }
}

.frame-space-before-large.frame-background-hatched {
  padding-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-hatched {
    padding-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-hatched {
    padding-top: 11.25rem;
  }
}

.frame-space-after-large.frame-background-none {
  margin-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-none {
    margin-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-none {
    margin-bottom: 11.25rem;
  }
}

.frame-space-after-large.frame-background-primary {
  padding-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-primary {
    padding-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-primary {
    padding-bottom: 11.25rem;
  }
}

.frame-space-after-large.frame-background-light {
  padding-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-light {
    padding-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-light {
    padding-bottom: 11.25rem;
  }
}

.frame-space-after-large.frame-background-dark {
  padding-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-dark {
    padding-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-dark {
    padding-bottom: 11.25rem;
  }
}

.frame-space-after-large.frame-background-hatched {
  padding-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-hatched {
    padding-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-hatched {
    padding-bottom: 11.25rem;
  }
}

.frame-space-before-small.frame-background-none {
  margin-top: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-none {
    margin-top: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-none {
    margin-top: 3.75rem;
  }
}

.frame-space-before-small.frame-background-primary {
  padding-top: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-primary {
    padding-top: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-primary {
    padding-top: 3.75rem;
  }
}

.frame-space-before-small.frame-background-light {
  padding-top: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-light {
    padding-top: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-light {
    padding-top: 3.75rem;
  }
}

.frame-space-before-small.frame-background-dark {
  padding-top: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-dark {
    padding-top: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-dark {
    padding-top: 3.75rem;
  }
}

.frame-space-before-small.frame-background-hatched {
  padding-top: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-hatched {
    padding-top: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-hatched {
    padding-top: 3.75rem;
  }
}

.frame-space-after-small.frame-background-none {
  margin-bottom: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-none {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-none {
    margin-bottom: 3.75rem;
  }
}

.frame-space-after-small.frame-background-primary {
  padding-bottom: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-primary {
    padding-bottom: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-primary {
    padding-bottom: 3.75rem;
  }
}

.frame-space-after-small.frame-background-light {
  padding-bottom: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-light {
    padding-bottom: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-light {
    padding-bottom: 3.75rem;
  }
}

.frame-space-after-small.frame-background-dark {
  padding-bottom: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-dark {
    padding-bottom: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-dark {
    padding-bottom: 3.75rem;
  }
}

.frame-space-after-small.frame-background-hatched {
  padding-bottom: 2.25rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-hatched {
    padding-bottom: 3rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-hatched {
    padding-bottom: 3.75rem;
  }
}

.frame-space-before-none {
  margin-top: 0;
  padding-top: 0;
}

.frame-space-after-none {
  margin-bottom: 0;
  padding-bottom: 0;
}

.frame-grid-columns .frame {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.frame-grid-columns .frame p {
  width: 100% !important;
}

@media (max-width: 991.98px) {
  .frame-grid-columns.xs-space > .row .col-12 {
    margin-bottom: 1.5rem;
  }
  .frame-grid-columns.xs-space > .row .col-12:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .frame-header h1.text-center, .frame-header h2.text-center {
    margin-left: auto;
    margin-right: auto;
  }
  .textmedia-right .frame-header h1, .textmedia-right .frame-header h2,
  .textmedia-left .frame-header h1,
  .textmedia-left .frame-header h2,
  .textpic-right .frame-header h1,
  .textpic-right .frame-header h2,
  .textpic-left .frame-header h1,
  .textpic-left .frame-header h2,
  .frame-grid-columns .frame-header h1,
  .frame-grid-columns .frame-header h2 {
    width: 100%;
    max-width: none;
  }
}

@media (min-width: 992px) {
  .frame-type-text .frame-inner > p,
  .frame-type-text .frame-inner > h1,
  .frame-type-text .frame-inner > h2,
  .frame-type-text .frame-inner > h3,
  .frame-type-text .frame-inner > h4,
  .frame-type-text .frame-inner > h5,
  .frame-type-text .frame-inner > ol,
  .frame-type-text .frame-inner > ul,
  .frame-type-text .frame-inner .news-detail-lead > p,
  .frame-type-text .frame-inner .news-detail-lead > h1,
  .frame-type-text .frame-inner .news-detail-lead > h2,
  .frame-type-text .frame-inner .news-detail-lead > h3,
  .frame-type-text .frame-inner .news-detail-lead > h4,
  .frame-type-text .frame-inner .news-detail-lead > h5,
  .frame-type-text .frame-inner .news-detail-lead > ol,
  .frame-type-text .frame-inner .news-detail-lead > ul,
  .frame-type-text .frame-inner .news-detail-text > p,
  .frame-type-text .frame-inner .news-detail-text > h1,
  .frame-type-text .frame-inner .news-detail-text > h2,
  .frame-type-text .frame-inner .news-detail-text > h3,
  .frame-type-text .frame-inner .news-detail-text > h4,
  .frame-type-text .frame-inner .news-detail-text > h5,
  .frame-type-text .frame-inner .news-detail-text > ol,
  .frame-type-text .frame-inner .news-detail-text > ul,
  .frame-type-textpic .textpic-above .textpic-text > p,
  .frame-type-textpic .textpic-above .textpic-text > h1,
  .frame-type-textpic .textpic-above .textpic-text > h2,
  .frame-type-textpic .textpic-above .textpic-text > h3,
  .frame-type-textpic .textpic-above .textpic-text > h4,
  .frame-type-textpic .textpic-above .textpic-text > h5,
  .frame-type-textpic .textpic-above .textpic-text > ol,
  .frame-type-textpic .textpic-above .textpic-text > ul,
  .frame-type-textpic .textpic-below .textpic-text > p,
  .frame-type-textpic .textpic-below .textpic-text > h1,
  .frame-type-textpic .textpic-below .textpic-text > h2,
  .frame-type-textpic .textpic-below .textpic-text > h3,
  .frame-type-textpic .textpic-below .textpic-text > h4,
  .frame-type-textpic .textpic-below .textpic-text > h5,
  .frame-type-textpic .textpic-below .textpic-text > ol,
  .frame-type-textpic .textpic-below .textpic-text > ul,
  .frame-type-textmedia .textmedia-above .textmedia-text > p,
  .frame-type-textmedia .textmedia-above .textmedia-text > h1,
  .frame-type-textmedia .textmedia-above .textmedia-text > h2,
  .frame-type-textmedia .textmedia-above .textmedia-text > h3,
  .frame-type-textmedia .textmedia-above .textmedia-text > h4,
  .frame-type-textmedia .textmedia-above .textmedia-text > h5,
  .frame-type-textmedia .textmedia-above .textmedia-text > ol,
  .frame-type-textmedia .textmedia-above .textmedia-text > ul,
  .frame-type-textmedia .textmedia-below .textmedia-text > p,
  .frame-type-textmedia .textmedia-below .textmedia-text > h1,
  .frame-type-textmedia .textmedia-below .textmedia-text > h2,
  .frame-type-textmedia .textmedia-below .textmedia-text > h3,
  .frame-type-textmedia .textmedia-below .textmedia-text > h4,
  .frame-type-textmedia .textmedia-below .textmedia-text > h5,
  .frame-type-textmedia .textmedia-below .textmedia-text > ol,
  .frame-type-textmedia .textmedia-below .textmedia-text > ul {
    width: 83.33333%;
    max-width: 60rem;
    margin-right: auto;
    margin-left: auto;
  }
  .frame-type-text .frame-inner > p.text-center,
  .frame-type-text .frame-inner > h1.text-center,
  .frame-type-text .frame-inner > h2.text-center,
  .frame-type-text .frame-inner > h3.text-center,
  .frame-type-text .frame-inner > h4.text-center,
  .frame-type-text .frame-inner > h5.text-center,
  .frame-type-text .frame-inner > ol.text-center,
  .frame-type-text .frame-inner > ul.text-center,
  .frame-type-text .frame-inner .news-detail-lead > p.text-center,
  .frame-type-text .frame-inner .news-detail-lead > h1.text-center,
  .frame-type-text .frame-inner .news-detail-lead > h2.text-center,
  .frame-type-text .frame-inner .news-detail-lead > h3.text-center,
  .frame-type-text .frame-inner .news-detail-lead > h4.text-center,
  .frame-type-text .frame-inner .news-detail-lead > h5.text-center,
  .frame-type-text .frame-inner .news-detail-lead > ol.text-center,
  .frame-type-text .frame-inner .news-detail-lead > ul.text-center,
  .frame-type-text .frame-inner .news-detail-text > p.text-center,
  .frame-type-text .frame-inner .news-detail-text > h1.text-center,
  .frame-type-text .frame-inner .news-detail-text > h2.text-center,
  .frame-type-text .frame-inner .news-detail-text > h3.text-center,
  .frame-type-text .frame-inner .news-detail-text > h4.text-center,
  .frame-type-text .frame-inner .news-detail-text > h5.text-center,
  .frame-type-text .frame-inner .news-detail-text > ol.text-center,
  .frame-type-text .frame-inner .news-detail-text > ul.text-center,
  .frame-type-textpic .textpic-above .textpic-text > p.text-center,
  .frame-type-textpic .textpic-above .textpic-text > h1.text-center,
  .frame-type-textpic .textpic-above .textpic-text > h2.text-center,
  .frame-type-textpic .textpic-above .textpic-text > h3.text-center,
  .frame-type-textpic .textpic-above .textpic-text > h4.text-center,
  .frame-type-textpic .textpic-above .textpic-text > h5.text-center,
  .frame-type-textpic .textpic-above .textpic-text > ol.text-center,
  .frame-type-textpic .textpic-above .textpic-text > ul.text-center,
  .frame-type-textpic .textpic-below .textpic-text > p.text-center,
  .frame-type-textpic .textpic-below .textpic-text > h1.text-center,
  .frame-type-textpic .textpic-below .textpic-text > h2.text-center,
  .frame-type-textpic .textpic-below .textpic-text > h3.text-center,
  .frame-type-textpic .textpic-below .textpic-text > h4.text-center,
  .frame-type-textpic .textpic-below .textpic-text > h5.text-center,
  .frame-type-textpic .textpic-below .textpic-text > ol.text-center,
  .frame-type-textpic .textpic-below .textpic-text > ul.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > p.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > h1.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > h2.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > h3.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > h4.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > h5.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > ol.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > ul.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > p.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > h1.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > h2.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > h3.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > h4.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > h5.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > ol.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > ul.text-center {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1420px) {
  .frame-type-text .frame-inner > p,
  .frame-type-text .frame-inner > h1,
  .frame-type-text .frame-inner > h2,
  .frame-type-text .frame-inner > h3,
  .frame-type-text .frame-inner > h4,
  .frame-type-text .frame-inner > h5,
  .frame-type-text .frame-inner > ol,
  .frame-type-text .frame-inner > ul,
  .frame-type-text .frame-inner .news-detail-lead > p,
  .frame-type-text .frame-inner .news-detail-lead > h1,
  .frame-type-text .frame-inner .news-detail-lead > h2,
  .frame-type-text .frame-inner .news-detail-lead > h3,
  .frame-type-text .frame-inner .news-detail-lead > h4,
  .frame-type-text .frame-inner .news-detail-lead > h5,
  .frame-type-text .frame-inner .news-detail-lead > ol,
  .frame-type-text .frame-inner .news-detail-lead > ul,
  .frame-type-text .frame-inner .news-detail-text > p,
  .frame-type-text .frame-inner .news-detail-text > h1,
  .frame-type-text .frame-inner .news-detail-text > h2,
  .frame-type-text .frame-inner .news-detail-text > h3,
  .frame-type-text .frame-inner .news-detail-text > h4,
  .frame-type-text .frame-inner .news-detail-text > h5,
  .frame-type-text .frame-inner .news-detail-text > ol,
  .frame-type-text .frame-inner .news-detail-text > ul,
  .frame-type-textpic .textpic-above .textpic-text > p,
  .frame-type-textpic .textpic-above .textpic-text > h1,
  .frame-type-textpic .textpic-above .textpic-text > h2,
  .frame-type-textpic .textpic-above .textpic-text > h3,
  .frame-type-textpic .textpic-above .textpic-text > h4,
  .frame-type-textpic .textpic-above .textpic-text > h5,
  .frame-type-textpic .textpic-above .textpic-text > ol,
  .frame-type-textpic .textpic-above .textpic-text > ul,
  .frame-type-textpic .textpic-below .textpic-text > p,
  .frame-type-textpic .textpic-below .textpic-text > h1,
  .frame-type-textpic .textpic-below .textpic-text > h2,
  .frame-type-textpic .textpic-below .textpic-text > h3,
  .frame-type-textpic .textpic-below .textpic-text > h4,
  .frame-type-textpic .textpic-below .textpic-text > h5,
  .frame-type-textpic .textpic-below .textpic-text > ol,
  .frame-type-textpic .textpic-below .textpic-text > ul,
  .frame-type-textmedia .textmedia-above .textmedia-text > p,
  .frame-type-textmedia .textmedia-above .textmedia-text > h1,
  .frame-type-textmedia .textmedia-above .textmedia-text > h2,
  .frame-type-textmedia .textmedia-above .textmedia-text > h3,
  .frame-type-textmedia .textmedia-above .textmedia-text > h4,
  .frame-type-textmedia .textmedia-above .textmedia-text > h5,
  .frame-type-textmedia .textmedia-above .textmedia-text > ol,
  .frame-type-textmedia .textmedia-above .textmedia-text > ul,
  .frame-type-textmedia .textmedia-below .textmedia-text > p,
  .frame-type-textmedia .textmedia-below .textmedia-text > h1,
  .frame-type-textmedia .textmedia-below .textmedia-text > h2,
  .frame-type-textmedia .textmedia-below .textmedia-text > h3,
  .frame-type-textmedia .textmedia-below .textmedia-text > h4,
  .frame-type-textmedia .textmedia-below .textmedia-text > h5,
  .frame-type-textmedia .textmedia-below .textmedia-text > ol,
  .frame-type-textmedia .textmedia-below .textmedia-text > ul {
    width: 66.66667%;
    margin-right: auto;
    margin-left: auto;
  }
}

.frame-type-text .frame-inner > header,
.frame-type-text .frame-inner .news-detail-lead > header,
.frame-type-text .frame-inner .news-detail-text > header,
.frame-type-textpic .textpic-above .textpic-text > header,
.frame-type-textpic .textpic-below .textpic-text > header,
.frame-type-textmedia .textmedia-above .textmedia-text > header,
.frame-type-textmedia .textmedia-below .textmedia-text > header {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .frame-type-text .frame-inner > header,
  .frame-type-text .frame-inner .news-detail-lead > header,
  .frame-type-text .frame-inner .news-detail-text > header,
  .frame-type-textpic .textpic-above .textpic-text > header,
  .frame-type-textpic .textpic-below .textpic-text > header,
  .frame-type-textmedia .textmedia-above .textmedia-text > header,
  .frame-type-textmedia .textmedia-below .textmedia-text > header {
    width: 83.33333%;
    max-width: 60rem;
  }
  .frame-type-text .frame-inner > header.text-center,
  .frame-type-text .frame-inner .news-detail-lead > header.text-center,
  .frame-type-text .frame-inner .news-detail-text > header.text-center,
  .frame-type-textpic .textpic-above .textpic-text > header.text-center,
  .frame-type-textpic .textpic-below .textpic-text > header.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > header.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > header.text-center {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1420px) {
  .frame-type-text .frame-inner > header,
  .frame-type-text .frame-inner .news-detail-lead > header,
  .frame-type-text .frame-inner .news-detail-text > header,
  .frame-type-textpic .textpic-above .textpic-text > header,
  .frame-type-textpic .textpic-below .textpic-text > header,
  .frame-type-textmedia .textmedia-above .textmedia-text > header,
  .frame-type-textmedia .textmedia-below .textmedia-text > header {
    width: 66.66667%;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .frame-grid-columns .frame-type-text .frame-inner > p,
  .frame-grid-columns .frame-type-text .frame-inner > h1,
  .frame-grid-columns .frame-type-text .frame-inner > h2,
  .frame-grid-columns .frame-type-text .frame-inner > h3,
  .frame-grid-columns .frame-type-text .frame-inner > h4,
  .frame-grid-columns .frame-type-text .frame-inner > h5,
  .frame-grid-columns .frame-type-text .frame-inner > ol,
  .frame-grid-columns .frame-type-text .frame-inner > ul,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > p,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h1,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h2,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h3,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h4,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h5,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > ol,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > ul,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > p,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h1,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h2,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h3,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h4,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h5,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > ol,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > ul,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > p,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h1,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h2,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h3,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h4,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h5,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > ol,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > ul,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > p,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h1,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h2,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h3,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h4,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h5,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > ol,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > ul,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > p,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h1,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h2,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h3,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h4,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h5,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > ol,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > ul,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > p,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h1,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h2,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h3,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h4,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h5,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > ol,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > ul {
    width: 100% !important;
  }
  .frame-grid-columns .frame-type-text .frame-inner > p.text-center,
  .frame-grid-columns .frame-type-text .frame-inner > h1.text-center,
  .frame-grid-columns .frame-type-text .frame-inner > h2.text-center,
  .frame-grid-columns .frame-type-text .frame-inner > h3.text-center,
  .frame-grid-columns .frame-type-text .frame-inner > h4.text-center,
  .frame-grid-columns .frame-type-text .frame-inner > h5.text-center,
  .frame-grid-columns .frame-type-text .frame-inner > ol.text-center,
  .frame-grid-columns .frame-type-text .frame-inner > ul.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > p.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h1.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h2.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h3.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h4.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > h5.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > ol.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > ul.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > p.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h1.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h2.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h3.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h4.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > h5.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > ol.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > ul.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > p.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h1.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h2.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h3.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h4.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > h5.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > ol.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > ul.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > p.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h1.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h2.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h3.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h4.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > h5.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > ol.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > ul.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > p.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h1.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h2.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h3.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h4.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > h5.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > ol.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > ul.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > p.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h1.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h2.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h3.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h4.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > h5.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > ol.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > ul.text-center {
    margin-left: auto;
    margin-right: auto;
  }
}

.frame-grid-columns .frame-type-text .frame-inner > header,
.frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > header,
.frame-grid-columns .frame-type-text .frame-inner .news-detail-text > header,
.frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > header,
.frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > header,
.frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > header,
.frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > header {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .frame-grid-columns .frame-type-text .frame-inner > header,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > header,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > header,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > header,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > header,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > header,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > header {
    width: 100% !important;
  }
  .frame-grid-columns .frame-type-text .frame-inner > header.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-lead > header.text-center,
  .frame-grid-columns .frame-type-text .frame-inner .news-detail-text > header.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-above .textpic-text > header.text-center,
  .frame-grid-columns .frame-type-textpic .textpic-below .textpic-text > header.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-above .textmedia-text > header.text-center,
  .frame-grid-columns .frame-type-textmedia .textmedia-below .textmedia-text > header.text-center {
    margin-left: auto;
    margin-right: auto;
  }
}

.frame-grid-columns header {
  width: 100% !important;
}

@media (min-width: 992px) {
  .frame-header h1 {
    line-height: 4.2rem;
  }
}

.frame-header h3 {
  line-height: 2.3rem;
}

/*@include media-breakpoint-down(md) {
        .frame-grid-columns .col-12 {
            margin-bottom: 2rem;
        }
    }*/
.textmedia,
.textpic {
  display: flex;
  flex-wrap: wrap;
  margin-left: -13px;
  margin-right: -13px;
}

.textmedia-item,
.textpic-item {
  width: 100%;
  padding-left: 13px;
  padding-right: 13px;
}

.textmedia-text,
.textpic-text {
  padding-top: 20px;
}

.textmedia-text > *:last-child,
.textpic-text > *:last-child {
  margin-bottom: 0;
}

.textmedia-below .textmedia-text,
.textmedia-below .textpic-text,
.textpic-below .textmedia-text,
.textpic-below .textpic-text,
.textmedia-right .textmedia-text,
.textmedia-right .textpic-text,
.textpic-right .textmedia-text,
.textpic-right .textpic-text {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .textmedia-right .textmedia-item,
  .textmedia-right .textpic-item,
  .textmedia-left .textmedia-item,
  .textmedia-left .textpic-item,
  .textpic-right .textmedia-item,
  .textpic-right .textpic-item,
  .textpic-left .textmedia-item,
  .textpic-left .textpic-item {
    margin-bottom: 0;
    width: 50%;
  }
}

.textmedia-above .gallery-item-size-1,
.textmedia-below .gallery-item-size-1 {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .textmedia-above .gallery-item-size-1,
  .textmedia-below .gallery-item-size-1 {
    width: 83.33333%;
  }
}

@media (min-width: 1420px) {
  .textmedia-above .gallery-item-size-1,
  .textmedia-below .gallery-item-size-1 {
    width: 66.66667%;
  }
}

@media (min-width: 768px) {
  .textpic-left.pic-25 .textpic-gallery,
  .textpic-right.pic-25 .textpic-gallery {
    width: 25%;
  }
  .textpic-left.pic-25 .textpic-text,
  .textpic-right.pic-25 .textpic-text {
    width: 75%;
  }
  .textpic-left.pic-75 .textpic-gallery,
  .textpic-right.pic-75 .textpic-gallery {
    width: 75%;
  }
  .textpic-left.pic-75 .textpic-text,
  .textpic-right.pic-75 .textpic-text {
    width: 25%;
  }
  .textpic-left.pic-50 .textpic-gallery,
  .textpic-right.pic-50 .textpic-gallery {
    width: 50%;
  }
  .textpic-left.pic-50 .textpic-text,
  .textpic-right.pic-50 .textpic-text {
    width: 50%;
  }
}

@media (max-width: 991.98px) {
  .textpic-right {
    display: flex;
    flex-direction: column-reverse;
  }
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -13px;
  margin-right: -13px;
}

.gallery-row.gallery-slider {
  overflow: hidden;
}

.gallery-row.gallery-slider .owl-stage {
  display: flex;
}

.gallery-row.gallery-slider .owl-stage .owl-item {
  display: flex;
  align-items: center;
}

.gallery-row.gallery-slider .owl-stage .owl-item .gallery-item {
  margin: 0 auto;
}

@media (min-width: 576px) {
  .gallery-row.gallery-slider .owl-stage .owl-item .gallery-item.gallery-item-size-2, .gallery-row.gallery-slider .owl-stage .owl-item .gallery-item.gallery-item-size-3, .gallery-row.gallery-slider .owl-stage .owl-item .gallery-item.gallery-item-size-4 {
    width: 60%;
  }
}

.gallery-row.gallery-slider .owl-stage .owl-item .gallery-item figure {
  margin-bottom: 0;
}

.gallery-row.gallery-slider .owl-nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.gallery-row.gallery-slider .owl-nav button {
  border: none;
  background: none;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-row.gallery-slider .owl-nav button svg {
  width: 100%;
  height: 100%;
}

.gallery-row.gallery-slider .owl-nav button.owl-next {
  transform: rotate(180deg);
}

.gallery-row.gallery-slider .owl-nav button:hover svg {
  fill: #002454;
}

.gallery-row.gallery-slider .owl-nav button:focus {
  border: none;
  outline: none;
}

.gallery-item {
  padding-left: 13px;
  padding-right: 13px;
}

.gallery-item img,
.gallery-item picture {
  /*width: 100%;*/
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.gallery-item figure {
  margin-bottom: 26px;
}

.gallery-item-size-1 {
  width: 100%;
}

.gallery-item-size-2 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-2 {
    width: calc(100% / 2);
  }
}

.gallery-item-size-3 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-3 {
    width: calc(99.9% / 3);
  }
}

.gallery-item-size-4 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-4 {
    width: calc(100% / 4);
  }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 2045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 2044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 2046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
             * Remove all paddings around the image on small screen
             */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.box-link {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  color: #fff;
  background-color: #333;
  font-family: "myriad-pro", sans-serif;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "myriad-pro", sans-serif;
  color: #FFCC00 !important;
  font-weight: 400;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.45);
}

@media (min-width: 768px) and (max-width: 1419.98px) {
  .box-link {
    font-size: 1.75rem;
    font-size: 1rem;
  }
}

@media (min-width: 1420px) {
  .box-link {
    font-size: 3rem;
    font-size: 1.6rem;
  }
}

@media (max-width: 767.98px) {
  .box-link {
    font-size: 1.4rem;
  }
}

.box-link .box-link-inner {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.box-link .box-link-inner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  background-color: transparent;
}

.box-link .box-link-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.4s;
}

.box-link-four .box-link-bg-img {
  position: relative;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.box-link .box-link-text {
  position: relative;
  z-index: 2;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
}

@media (min-width: 768px) {
  .box-link .box-link-text {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .box-link .box-link-text {
    font-size: 2rem;
  }
}

.box-link:hover {
  color: #fff;
  text-decoration: none;
}

.box-link:hover .box-link-bg {
  transform: scale(1.05);
}

.box-link:focus {
  color: #fff;
  text-decoration: none;
}

.webp .box-link-one .box-link-bg {
  background-image: url("../../Images/had-teaser-penthouse-lounge.webp");
  background-image: url("../../Images/adm-teaser-responsibility.webp");
}

.webp .box-link-two .box-link-bg {
  background-image: url("../../Images/had-teaser-online-buchen.webp");
  background-image: url("../../Images/adm-teaser-carrer.webp");
}

.webp .box-link-three .box-link-bg {
  background-image: url("../../Images/had-teaser-cafe-bar.webp");
  background-image: url("../../Images/adm-teaser-about.webp");
}

.webp .box-link-four .box-link-bg {
  background-image: url("../../Images/had-teaser-gutscheine.webp");
  background-image: url("../../Images/adm-teaser-contact.webp");
}

.no-webp .box-link-one .box-link-bg {
  background-image: url("../../Images/had-teaser-penthouse-lounge.jpg");
  background-image: url("../../Images/adm-teaser-betting.jpg");
}

.no-webp .box-link-two .box-link-bg {
  background-image: url("../../Images/had-teaser-online-buchen.jpg");
  background-image: url("../../Images/adm-teaser-carrer.jpg");
}

.no-webp .box-link-three .box-link-bg {
  background-image: url("../../Images/had-teaser-cafe-bar.jpg");
  background-image: url("../../Images/adm-teaser-carrer.jpg");
}

.no-webp .box-link-four .box-link-bg {
  background-image: url("../../Images/had-teaser-gutscheine.jpg");
  background-image: url("../../Images/adm-teaser-contact.jpg");
}

.content-boxes-wrapper .box-link {
  height: 0;
  padding-bottom: 75%;
}

@media (min-width: 992px) {
  .menu-overlay .menu-boxes-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 360px;
  }
  .menu-overlay .menu-boxes-wrapper .box-link {
    height: calc(100% / 4);
  }
}

@media (min-width: 1420px) {
  .menu-overlay .menu-boxes-wrapper {
    width: 460px;
  }
}

.infoteaser-btn {
  position: fixed;
  right: 10px;
  bottom: 70px;
  display: block;
  width: 48px;
  height: 48px;
  padding: 10px;
  background: #f1f1f1;
  border: 0;
  border-radius: 24px;
  color: #002454;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  z-index: 1078;
  appearance: none;
  pointer-events: auto;
  background: #fff;
  padding: 7px;
  width: 70px;
  height: 70px;
  border-radius: 50px;
}

.infoteaser-btn:focus, .infoteaser-btn.focus {
  outline-color: #ced4da;
  outline-color: #f1f1f1;
}

.infoteaser-btn > svg {
  width: 100%;
  height: 100%;
  margin-top: 4px;
}

@media (min-width: 992px) {
  .infoteaser-btn {
    width: 68px;
    height: 68px;
    padding: 14px;
    border-radius: 34px;
    bottom: 10px;
    width: 90px;
    height: 90px;
    padding: 8px;
    border-radius: 50px;
    bottom: 25px;
    right: 25px;
  }
  .infoteaser-btn > svg {
    margin-top: 4px;
  }
}

.infoteaser-items-wrapper {
  position: fixed;
  display: flex;
  align-items: flex-end;
  top: 10px;
  right: 10px;
  left: 10px;
  bottom: 128px;
  z-index: 1078;
  pointer-events: none;
  bottom: 150px;
}

.infoteaser-items-wrapper.show-it .infoteaser-inner {
  animation-name: infoIn;
  animation-duration: 250ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.infoteaser-items-wrapper.hide-it .infoteaser-inner {
  animation-name: infoOut;
  animation-duration: 250ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.infoteaser-items-wrapper .infoteaser-inner {
  position: relative;
  padding: 4rem 1.25rem 1.25rem;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  pointer-events: auto;
}

@media (min-width: 576px) {
  .infoteaser-items-wrapper {
    left: auto;
    width: 430px;
    width: 475px;
  }
}

@media (min-width: 992px) {
  .infoteaser-items-wrapper {
    right: 40px;
    bottom: 98px;
    bottom: 125px;
  }
  .infoteaser-items-wrapper .infoteaser-inner {
    padding: 4rem 2.625rem 2.625rem;
  }
}

.infoteaser-title {
  font-size: 2.188rem;
  font-size: 1.563rem;
}

@media (min-width: 576px) {
  .infoteaser-title {
    font-size: 2.188rem;
  }
}

.infoteaser-text {
  font-weight: 300;
}

.infoteaser-close {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 26px;
  height: 26px;
  display: block;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.infoteaser-close:focus, .infoteaser-close.focus {
  outline-color: #ced4da;
}

.infoteaser-close::before, .infoteaser-close::after {
  content: '';
  position: absolute;
  left: 12px;
  top: 0;
  height: 26px;
  width: 1px;
  background-color: #333;
}

.infoteaser-close::before {
  transform: rotate(45deg);
}

.infoteaser-close::after {
  transform: rotate(-45deg);
}

@keyframes infoIn {
  0% {
    opacity: 0;
    transform: scale(0.9) translate3d(1.5rem, 0.5rem, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes infoOut {
  0% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.9) translate3d(1.5rem, 0.5rem, 0);
  }
}

.frame-type-wvier_selftest .selftest-wrapper {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table {
  width: 100%;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .yes, .frame-type-wvier_selftest .selftest-wrapper .selftest-table .no,
.frame-type-wvier_selftest .selftest-wrapper .selftest-table .input-yes, .frame-type-wvier_selftest .selftest-wrapper .selftest-table .input-no {
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  width: 50px;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question {
  border-bottom: 2px solid #ced4da;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .question {
  display: flex;
  padding: 20px 20px 20px 0;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .question label {
  color: #002454;
  font-size: 20px;
  font-family: "myriad-pro", sans-serif;
  margin: 0 10px 0 0;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .question p {
  margin: 0;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-yes input[type=radio], .frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-no input[type=radio] {
  display: none;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-yes input[type=radio]:hover + label span, .frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-no input[type=radio]:hover + label span {
  background-color: #ced4da;
  cursor: pointer;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-yes input[type=radio]:checked + label span, .frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-no input[type=radio]:checked + label span {
  background-color: #002454;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-yes label, .frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-no label {
  display: inline-block;
  height: 30px;
  width: 30px;
}

.frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-yes label .radiobtn, .frame-type-wvier_selftest .selftest-wrapper .selftest-table .selftest-question .input-no label .radiobtn {
  display: inline-block;
  height: 30px;
  width: 30px;
  background-color: #e9e9e9;
  border-radius: 50px;
}

.frame-type-wvier_selftest .selftest-wrapper button {
  margin-top: 50px;
}

.frame-type-wvier_selftest .modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5050;
  outline: 0;
}

@media (min-width: 992px) {
  .frame-type-wvier_selftest .modal {
    margin-top: 100px;
  }
}

.frame-type-wvier_selftest .modal .modal-content {
  position: relative;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  border: none;
}

.frame-type-wvier_selftest .modal .modal-content .modal-header {
  padding: 15px;
  border: none;
}

.frame-type-wvier_selftest .modal .modal-content .modal-header .close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  height: 15px;
}

.frame-type-wvier_selftest .modal .modal-content .modal-header .close:before, .frame-type-wvier_selftest .modal .modal-content .modal-header .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 20px;
  width: 2px;
  top: 6px;
  background-color: #333;
}

.frame-type-wvier_selftest .modal .modal-content .modal-header .close:before {
  transform: rotate(45deg);
}

.frame-type-wvier_selftest .modal .modal-content .modal-header .close:after {
  transform: rotate(-45deg);
}

.frame-type-wvier_selftest .modal .modal-content .modal-body {
  text-align: center;
  padding: 30px 50px 50px;
}

.frame-type-wvier_selftest .modal .modal-content .modal-body .headline_negative {
  color: red;
}

.frame-type-wvier_selftest .modal .modal-content .modal-body .headline_success {
  color: green;
}

::selection {
  background: #657696;
  /* WebKit/Blink Browsers */
  color: #fff;
}

::-moz-selection {
  background: #657696;
  /* Gecko Browsers */
  color: #fff;
}

ul {
  list-style: none;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item) {
  position: relative;
  line-height: 1.563rem;
  padding-left: 10px;
  padding-bottom: 8px;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item):last-child {
  padding-bottom: 0;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item)::before {
  content: "\2022";
  color: #002454;
  font-weight: 700;
  font-size: 2.5rem;
  display: inline-block;
  width: 1rem;
  left: 0;
  margin-left: -1rem;
  position: absolute;
  top: -5px;
}

.skiplinks .sr-only:focus {
  display: inline-block;
  padding: 0.5rem;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  background-color: #fff;
  z-index: 1090;
}

.header-caption.animated {
  animation: fadeInHeader 1.4s linear 0.5s forwards;
}

.container .container {
  padding-left: 0;
  padding-right: 0;
}

.news-single .elements-wrapper .frame {
  margin-top: 2rem;
  margin-bottom: 0;
}

.news-single .news-detail-lead,
.news-single .news-detail-gallery {
  margin-top: 2rem;
}

.news-single .news-detail-text {
  margin-top: 1.5rem;
}

.frame-type-uploads .media-list .media {
  list-style-type: none;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
}

.frame-type-uploads .media-list .media .uploads-fileicon {
  margin-right: 10px;
}

.frame-type-uploads .media-list .media a {
  color: #333;
  text-decoration: none;
}

.frame-type-uploads .media-list .media a:hover {
  color: #002454;
  text-decoration: underline;
}

.frame-type-uploads .media-list .media a:hover .uploads-fileicon svg {
  fill: #002454;
}

.frame-type-uploads .media-list .media::before {
  display: none !important;
}

.footer {
  font-weight: 300;
}

.footer .footer-content.bg-white {
  position: relative;
  background-color: #fff;
  color: #333;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  font-size: 0.813rem;
  line-height: 1.125rem;
}

@media screen and (min-width: 450px) {
  .footer .footer-content.bg-white {
    padding-bottom: 0;
    height: 220px;
  }
}

@media (min-width: 768px) {
  .footer .footer-content.bg-white {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 1420px) {
  .footer .footer-content.bg-white {
    padding-top: 0.313rem;
    padding-bottom: 0.313rem;
  }
}

.footer .footer-content.bg-dark {
  background-color: #002454;
}

.footer .footer-content.bg-gray {
  background-color: #F3F3F3;
  color: #333;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

@media (min-width: 1420px) {
  .footer .footer-content.bg-gray {
    padding-top: 0.938rem;
    padding-bottom: 0.938rem;
  }
}

.footer .footer-content.bg-gray a.text-white {
  color: #333 !important;
  margin-left: 15px;
  margin-right: 15px;
}

.footer .footer-content.bg-gray li:first-of-type a.text-white {
  margin-left: 0;
}

.footer .footer-content.bg-gray li:last-of-type a.text-white {
  margin-left: 0;
}

.footer .footer-content.bg-gray .list-unstyled {
  margin-bottom: 0;
}

.footer .w4-adress-1 {
  margin-bottom: 2.813rem;
  margin-top: 0;
}

.footer .w4-spielerschutz-1 {
  align-self: flex-start;
}

@media (min-width: 768px) {
  .footer .w4-spielerschutz-1 {
    align-self: center;
  }
}

.footer .w4-spielerschutz-3 {
  position: absolute;
  top: 120px;
}

@media (min-width: 768px) {
  .footer .w4-spielerschutz-3 {
    position: initial;
  }
}

.footer .w4-spielerschutz-3 img {
  margin-right: 3px;
}

@media screen and (min-width: 410px) {
  .footer .w4-spielerschutz-3 img {
    margin-right: 15px;
  }
}

.footer .footer-content {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

@media (min-width: 1420px) {
  .footer .footer-content {
    padding-top: 7rem;
    padding-bottom: 7rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: 1419.98px) {
  .footer .footer-content .footer-address {
    width: 250px;
  }
}

.footer .h2 {
  width: 100%;
}

@media (min-width: 992px) {
  .footer .h2 {
    width: 600px;
  }
}

.footer .h2:after {
  border-bottom: 0;
}

@media (min-width: 992px) {
  .footer .footer-partners {
    width: 600px;
  }
}

.footer-nav {
  text-align: center;
  font-size: 0.875rem;
}

@media (min-width: 992px) {
  .footer-nav {
    width: 600px;
    width: auto;
  }
}

.footer-nav .list-item + .list-item {
  margin-top: 0.437rem;
  margin-bottom: 0.437rem;
}

.footer-nav li {
  display: inline;
  display: inline-block;
}

.footer .footer-social {
  margin-top: 1.625rem;
  margin-bottom: 1.875rem !important;
  margin-top: 1.875rem;
  margin-bottom: 2.5rem !important;
}

@media (min-width: 992px) {
  .footer .footer-social {
    margin-top: 2.375rem;
  }
}

.footer .footer-facebook,
.footer .footer-instagram,
.footer .footer-twitter,
.footer .footer-youtube {
  text-indent: -9999px;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.footer .footer-facebook {
  background: url("../../Icons/Theme/facebook.svg") no-repeat;
}

.footer .footer-instagram {
  background: url("../../Icons/Theme/instagram.svg") no-repeat;
  margin-left: 15px;
}

.footer .footer-facebook,
.footer .footer-instagram,
.footer .footer-twitter,
.footer .footer-youtube {
  height: 40px;
  width: 40px;
  background-size: contain !important;
}

@media (min-width: 1420px) {
  .footer .footer-facebook,
  .footer .footer-instagram,
  .footer .footer-twitter,
  .footer .footer-youtube {
    width: 44px;
    height: 44px;
  }
}

.footer .footer-facebook {
  background: url("../../Icons/Theme/adm-facebook.svg") no-repeat;
}

.footer .footer-instagram {
  background: url("../../Icons/Theme/adm-instagram.svg") no-repeat;
  margin-left: 15px;
}

.footer .footer-twitter {
  background: url("../../Icons/Theme/adm-twitter.svg") no-repeat;
  margin-left: 15px;
}

.footer .footer-youtube {
  background: url("../../Icons/Theme/adm-youtube.svg") no-repeat;
  margin-left: 15px;
}

@media (max-width: 575.98px) {
  .footer-map {
    margin-left: -13px;
    margin-right: -13px;
  }
}

.figure-caption {
  margin-top: 0.25rem;
  text-align: center;
}

@media (min-width: 768px) {
  .frame-default.off-screen .frame-inner {
    transition: 0.8s ease;
    opacity: 0;
    transform: translate(0, 120px);
  }
  .frame-default.off-screen.animated .frame-inner {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes bounceScrollDown {
  0%, 100% {
    transform: translateX(0);
  }
  44% {
    transform: translateX(-12px);
  }
}

@keyframes bounceRight {
  0%, 100% {
    transform: translateX(0);
  }
  56% {
    transform: translateX(12px);
  }
}

@keyframes fadeInHeader {
  0% {
    opacity: 0;
    transform: translate(1.5rem, 0.5rem);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

ul li,
ol li {
  font-size: 1rem;
}

@media (min-width: 1420px) {
  ul li,
  ol li {
    font-size: 1.125rem;
  }
}

.frame-type-felogin_login .frame-inner {
  margin-left: auto;
  margin-right: auto;
}

.frame-type-felogin_login .frame-inner h3,
.frame-type-felogin_login .frame-inner p {
  text-align: center;
}

.frame-type-felogin_login .frame-inner .form-control {
  border-color: #002454;
}

.frame-type-felogin_login .frame-inner .form-control:focus {
  box-shadow: none;
  outline: 2px solid #FFCC00;
}

@media (min-width: 992px) {
  .frame-type-felogin_login .frame-inner {
    width: 83.33333%;
    max-width: 60rem;
  }
}

@media (min-width: 1420px) {
  .frame-type-felogin_login .frame-inner {
    width: 66.66667%;
  }
}

.adm-login label,
.layout_form label {
  font-weight: bold;
  font-size: 1.25rem;
}

.layout_form .cr_body {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  #store-finder-widget .ubsf_locations-list-item .ubsf_locations-list-item-additional-info li:before {
    margin-left: initial;
  }
  #store-finder-widget ul.ubsf_details-services-list li::before,
  #store-finder-widget ul.ubsf_details-languages-list li::before,
  #store-finder-widget ul.ubsf_details-social-profiles-list li::before,
  #store-finder-widget ul.ubsf_sitemap-list li::before,
  #store-finder-widget ul.ubsf_sitemap-grouped-locations li::before {
    display: none;
  }
  .page-wrapper #store-finder-widget .ubsf_outer-wrap {
    margin-bottom: 77px;
    padding-top: 3rem;
  }
  #store-finder-widget .ubsf_info-window-content-info li:before {
    left: -15px;
  }
  .page-wrapper #store-finder-widget .ubsf_store-finder-wrapper.main {
    position: relative;
    max-width: none;
  }
  .page-wrapper #store-finder-widget .ubsf_main-page-content {
    padding-top: 50px;
  }
  .page-wrapper #store-finder-widget .ubsf_storefinder-search-box {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    width: 400px;
    max-width: 100%;
    z-index: 99;
  }
  .page-wrapper #store-finder-widget .ubsf_auto-complete-overlay {
    display: none;
  }
  .page-wrapper #store-finder-widget .ubsf_main-page-content-inner {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.999px) {
  .page-wrapper #store-finder-widget .ubsf_main-page-content-inner {
    min-height: 92.5vh;
  }
}

@media screen and (min-width: 768px) {
  .page-wrapper #store-finder-widget .ubsf_main-page-content-filters-wrapper {
    width: 0;
  }
  .page-wrapper #store-finder-widget .ubsf_store-finder-column.ubsf_store-finder-map-column.ubsf_map-hidden {
    pointer-events: all;
    opacity: 1;
  }
  .page-wrapper #store-finder-widget .ubsf_filter-box, .page-1 #store-finder-widget .ubsf_locations-list {
    display: none;
  }
  .page-wrapper #store-finder-widget .ubsf_store-finder-column.ubsf_store-finder-map-column {
    max-width: none;
  }
}

/* LS Cookie Consent */
.tx-ls-cookie-consent h3 {
  margin-bottom: 1.5rem;
}

.tx-ls-cookie-consent .c-p-table table thead th {
  background-color: #ced4da;
  padding: 10px;
  border-left: #7c7c7c solid 1px;
  text-align: center;
}

.tx-ls-cookie-consent .c-p-table table thead th:first-child {
  border-left: none;
}

.tx-ls-cookie-consent .c-p-table table tbody .c-p-table__group {
  background-color: #f1f1f1;
  margin-bottom: 5px;
}

.tx-ls-cookie-consent .c-p-table table tbody tr:not(.c-p-table__group) {
  border-bottom: #7c7c7c solid 1px;
}

.tx-ls-cookie-consent .c-p-table table tbody td {
  padding: 5px;
}

.cookie-overlay {
  z-index: 10000;
  background-color: rgba(51, 51, 51, 0.5);
  text-align: center;
  padding: 20px;
}

.cookie-overlay.cookie-overlay-youtube {
  position: absolute;
  top: 0;
  bottom: 0;
}
