//
// Base styles
//

.btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;

    @include hover {
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 1px dotted;
        outline-offset: 2px;
    }

    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        pointer-events: none;
    }

    @if($env == 'adm') {
        margin-bottom: 10px;
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-default {
    color: $white;
    background-color: $primary;
    border-color: $primary;
    font-size: $btn-font-size;
    text-transform: uppercase;
    padding: $btn-padding-y $btn-padding-x;
    transition: $btn-transition;

    @if($env == 'adm'){
        text-transform: none;
        border-radius: 100px;
    }

    @include hover {
        color: $body-color;
        background-color: $gray-300;
        border-color: $gray-300;

        @if($env == 'adm'){
            color: $primary;
            background-color: $white;
            border-color: $primary;
        }
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.2);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: $white;
        background: darken($primary, 6%);
    }

    @include media-breakpoint-up(lg) {
        font-size: $btn-font-size-lg;
        padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
}

.btn-secondary {
    color: $primary;
    background-color: $gray-300;
    border-color: $gray-300;
    font-size: $btn-font-size;
    text-transform: uppercase;
    padding: $btn-padding-y $btn-padding-x;
    transition: $btn-transition;

    @if($env == 'adm'){
        background-color: $secondary;
        border-color: $secondary;
        text-transform: none;
        border-radius: 100px;
    }

    @include hover {
        color: $white;
        background-color: $primary;
        border-color: $primary;

        @if($env == 'adm'){
            color: $primary;
            background-color: darken($secondary, 6%);
            border-color: darken($secondary, 6%);
        }
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.2);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: $white;
        background: darken($primary, 6%);
    }

    @include media-breakpoint-up(lg) {
        font-size: $btn-font-size-lg;
        padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
}


.btn-white {
    color: $body-color;
    background-color: rgba($dark, 0);
    border-color: $body-color;

    font-size: $btn-font-size;
    text-transform: uppercase;
    padding: $btn-padding-y $btn-padding-x;
    transition: $btn-transition;

    @include hover {
        color: $body-color;
        background-color: $gray-300;
        border-color: $gray-300;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($white, 0.2);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background: rgba($dark, 0.5);
    }
}

.btn-sm {
    position: relative;
    color: $dark;
    font-size: $btn-sm-font-size;
    text-transform: uppercase;
    padding: $btn-sm-padding-y $btn-sm-padding-x;

    @if($env == 'adm'){
        background-color: $secondary;
        border-color: $secondary;
        text-transform: none;
        border-radius: 100px;
        font-size: $btn-font-size;
        padding: $btn-padding-y $btn-padding-x;
        transition: $btn-transition;

        @include media-breakpoint-up(lg) {
            font-size: $btn-font-size-lg;
            padding: $btn-padding-y-lg $btn-padding-x-lg;
        }
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($dark, 0.2);

        &::before,
        &::after {
            width: 100%;
            height: 100%;
            border-color: $dark;
        }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background: darken($light, 6%);
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        background: transparent;

        @if($env == 'adm'){
            content: none;
        }
    }

    &::before  {
        bottom: 0;
        right: 0;
        width: 100%;
        border-bottom: 1px solid $dark;
        border-right: 1px solid $dark;
        transition-property: height;
        transition-delay: 0.4s;
        transition-duration: 0.2s;
    }

    &::after  {
        top: 0;
        right: 0;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        transition-property: height, width, border-color;
        transition-delay: 0s, 0.2s, 0.4s;
        transition-duration: 0.2s, 0.2s, 0s;
    }

    @include hover {
        &::before  {
            height: 100%;
            transition-delay: 0s;
        }

        &::after {
            width: 100%;
            height: 100%;
            border-top-color: $dark;
            border-left-color: $dark;
            transition-property: border-color, width, height;
            transition-delay: 0.2s, 0.2s, 0.4s;
            transition-duration: 0s, 0.2s, 0.2s;
        }

        @if($env == 'adm'){
            color: $primary;
            background-color: darken($secondary, 6%);
            border-color: darken($secondary, 6%);
        }
    }
}

.btn-yellow {
    position: relative;
    color: $primary;
    font-size: $btn-sm-font-size;
    text-transform: uppercase;
    padding: $btn-sm-padding-y $btn-sm-padding-x;

    @if($env == 'adm'){
        background-color: $secondary;
        border-color: $secondary;
        text-transform: none;
        border-radius: 100px;
        font-size: $btn-font-size;
        padding: $btn-padding-y $btn-padding-x;
        transition: $btn-transition;

        @include media-breakpoint-up(lg) {
            font-size: $btn-font-size-lg;
            padding: $btn-padding-y-lg $btn-padding-x-lg;
        }
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($secondary, 0.2);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background: darken($secondary, 6%);
    }


    @include hover {
        @if($env == 'adm'){
            color: $primary;
            background-color: darken($secondary, 6%);
            border-color: darken($secondary, 6%);
        }
    }
}

.frame-background,
.bg {
    &-dark,
    &-primary {
        .btn-default {
            background-color: rgba($dark, 0);
            border-color: $white;

            @include hover {
                color: $body-color;
                background-color: $gray-300;
                border-color: $gray-300;
            }

            &:focus,
            &.focus {
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba($white, 0.2);
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background: rgba($dark, 0.5);
            }
        }

        .btn-sm {
            color: $white;

            &:focus,
            &.focus {
                box-shadow: 0 0 0 0.2rem rgba($white, 0.2);

                &::before,
                &::after {
                    border-color: $white;
                }
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background: rgba($dark, 0.5);
            }

            &::before  {
                border-bottom-color: $white;
                border-right-color: $white;
            }

            &:hover {
                &::after {
                    border-top-color: $white;
                    border-left-color: $white;
                }
            }
        }
    }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    color: $link-color;
    text-decoration: $link-decoration;
    border: 0;

    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    // No need for an active state here
}


//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}
