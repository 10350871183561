.box-link {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    color: $white;
    background-color: $dark;
    font-family: $btn-font-family;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: $font-weight-bold;

    @if($env == 'adm'){
        font-size: 1.8rem;
        font-family: $headings-font-family;
        color: $secondary !important;
        font-weight: $font-weight-normal;
        text-shadow: 0 3px 6px $black-transparent;
    }

    @include media-breakpoint-between(md, lg) {
        font-size: 1.75rem;

        @if($env == 'adm'){
            font-size: 1rem;
        }
    }

    @include media-breakpoint-up(xl) {
        font-size: 3rem;

        @if($env == 'adm'){
            font-size: 1.6rem;
        }
    }


    @if($env == 'adm'){
        @include media-breakpoint-down(sm) {
            font-size: 1.4rem;
        }
    }

    .box-link-inner {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($black, 0.25);
            z-index: 1;

            @if($env == 'adm'){
                background-color: transparent;
            }
        }
    }

    .box-link-bg  {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 0.4s;
    }

    &-four {
        .box-link-bg-img {
            position: relative;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .box-link-text {
        position: relative;
        z-index: 2;
        text-align: center;

        @if($env == 'adm'){
            font-weight: $font-weight-bold;
            font-size: 2rem;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }

            @include media-breakpoint-up(lg) {
                font-size: 2rem;
            }
        }
    }

    &:hover {
        color: $white;
        text-decoration: none;

        .box-link-bg {
            transform: scale(1.05);
        }
    }

    &:focus {
        color: $white;
        text-decoration: none;
    }

}

.webp {
    .box-link-one {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-penthouse-lounge.webp");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-zimmer.webp");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-responsibility.webp");
            }
        }
    }

    .box-link-two {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-online-buchen.webp");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-online-buchen.webp");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-carrer.webp");
            }
        }
    }

    .box-link-three {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-cafe-bar.webp");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-kontakt.webp");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-about.webp");
            }
        }
    }

    .box-link-four {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-gutscheine.webp");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-had.webp");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-contact.webp");
            }
        }
    }
}

.no-webp {
    .box-link-one {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-penthouse-lounge.jpg");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-zimmer.jpg");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-betting.jpg");
            }
        }
    }

    .box-link-two {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-online-buchen.jpg");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-online-buchen.jpg");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-carrer.jpg");
            }
        }
    }

    .box-link-three {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-cafe-bar.jpg");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-kontakt.jpg");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-carrer.jpg");
            }
        }
    }

    .box-link-four {
        .box-link-bg {
            background-image: url("../../Images/had-teaser-gutscheine.jpg");

            @if($env == 'mov'){
                background-image: url("../../Images/mov-teaser-had.jpg");
            }

            @if($env == 'adm'){
                background-image: url("../../Images/adm-teaser-contact.jpg");
            }
        }
    }
}

.content-boxes-wrapper {
    .box-link {
        height: 0;
        padding-bottom: 75%;
    }
}

@include media-breakpoint-up(lg) {
    .menu-overlay {
        .menu-boxes-wrapper {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 360px;
            .box-link {
                height: calc(100% / 4);
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .menu-overlay {
        .menu-boxes-wrapper {
            width: 460px;
        }
    }
}
