// Navbar

.menu-overlay {

    @include media-breakpoint-up(lg) {
        .menu-bg-image {
            position: absolute;
            bottom: -20%;
            right: 10%;
            opacity: 0.15;
            width: 650px;
            height: 650px;
            z-index: -1;
        }
    }

    @include media-breakpoint-up(xl) {
        background-position: 80% bottom;
    }

    .menu-inner {
        position: relative;
        height: 100%;
        padding-bottom: 60px;
        overflow-x: hidden;
        overflow-y: auto;

        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
            padding-left: 90px;
            padding-right: 360px;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 460px;
        }
    }

    .navbar-nav {
        @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: 3rem auto 0;
            max-width: map_get($grid-breakpoints, lg);

            > .nav-item {
                flex-basis: calc(100% / 2);
            }
        }

        @if($env == 'adm') {
            @media (min-width: 1250px) {
                > .nav-item {
                    flex-basis: calc(99.9% / 3);
                    max-width: 33.3%;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            > .nav-item {
                flex-basis: calc(99.9% / 3);
                max-width: 33.3%;
            }
        }

        @if($env == 'adm'){
            @media (min-width: 1700px) {
                max-width: 1100px;
            }
        }
    }

    .first-level {
        position: relative;

        @if($env == 'adm') {
            > .nav-link {
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
            }
        }

        @include media-breakpoint-down(md) {
            > .nav-link {
                font-size: 1.75rem;
                border-bottom: 1px solid $gray-100;
                white-space: normal;

                @if($env == 'adm'){
                    font-size: 1.5rem;
                    border-bottom: 0;
                }

                > .svg-icon {
                    font-size: 1.25rem;
                    margin-left: 0.5rem;

                    @if($env == 'adm'){
                        transform: rotate(-90deg);
                    }
                }
            }

            @if($env == 'adm') {
                &.dropdown.show {
                    .nav-link {
                        .svg-icon {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            margin-top: 2rem;

            > .nav-link {
                font-family: $btn-font-family;
                font-size: 1.7rem;

                @if($env == 'adm'){
                    font-family: $headings-font-family;
                    font-size: 1.375rem;
                    white-space: initial;
                }

                > .svg-icon {
                    display: none;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            > .nav-link {
                font-size: 2.2rem;

                @if($env == 'adm') {
                    font-size: 1.375rem;

                }
            }
        }

        @if($env == 'adm') {
            @media (min-width: 1700px) {
                > .nav-link {
                    font-size: 1.813rem;
                }
            }
        }
    }

    .second-level {
        > .nav-link {
            font-size: 1.125rem;

            @if($env == 'adm'){
                font-weight: 300;
                border-bottom: 1px solid $gray-100;
                margin-left: 1.25rem;
                margin-right: 1.25rem;

                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                    margin-right: 0;
                    border-bottom: 0;
                }
            }
        }

        @include media-breakpoint-down(md) {
            > .nav-link {
                padding-left: ($nav-link-padding-x)*2;

                @if($env == 'adm'){
                    padding-left: 0.7rem;
                }
            }
        }
    }

    @if($env == 'adm') {
         .dropdown-menu .nav-item:last-child a {
            border-bottom: 0;
        }
    }

    .dropdown-menu {
        position: static;
        float: none;
        width: 100%;
        margin: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid $gray-100;
        background-color: transparent;

        @if($env == 'adm'){
            border-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            display: block!important;
            border: 0;
        }
    }
}

.navbar-nav {
    @include list-unstyled();
    margin: 0;
}

.nav-link {
    position: relative;
    display: block;
    line-height: 1;
    transition: all 0.2s;
    color: $dark;

    @if($env == 'adm'){
        color: $primary;
        text-decoration: none !important;
    }

    &:hover {
        color: darken($dark, 15%);

        @if($env == 'adm'){
            color: $primary !important;
            background-color: $secondary;
            text-decoration: underline !important;

            @include media-breakpoint-up(lg) {
                background-color: transparent;
            }

        }
    }

    &:focus {
        outline: 1px dotted;
        outline-offset: -2px;
    }

    @include media-breakpoint-down(md) {
        font-family: $btn-font-family;
        padding: $nav-link-padding-y $nav-link-padding-x;

        &.active {
            background-color: $primary;
            color: $white;

            @if($env == 'adm'){
                background-color: $secondary;
                color: $primary;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding: $nav-link-padding-y-lg $nav-link-padding-x;

        &.active,
        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
}

.menu-open {
    overflow: hidden;
}

.scrolled,
.menu-open {
    .navbar-toggler .navbar-toggler-text {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: visibility 0s 0.2s, opacity 0.2s;
    }
}
