// Frame

.frame {
    position: relative;
    padding-left: 7px;
    padding-right: 7px;
    //Frames without background get margins
    @each $breakpoint in map-keys($frame-spacing-scaling) {
        @include media-breakpoint-up($breakpoint) {
            $scaling: map-get($frame-spacing-scaling, $breakpoint);
            margin-top: ($frame-spacing * $scaling);
            margin-bottom: ($frame-spacing * $scaling);
        }
    }


    > *:last-child {
        margin-bottom: 0;
    }

    &-inner {
        > *:last-child {
            margin-bottom: 0;
        }
    }
}

// - Frame Styles
.frame-ruler-before {
    border-top: $hr-border-width solid $hr-border-color;
    .frame-inner {
        margin-top: $spacer;
    }
}

.frame-ruler-after {
    border-bottom: $hr-border-width solid $hr-border-color;
    .frame-inner {
        margin-bottom: $spacer;
    }
}

// - Background Color
@mixin make-frame-background($name, $settings) {
    $background: map-get($settings, 'background');
    $color: color-yiq($background);
    $link-color: map-get($settings, 'link-color');
    $link-hover-color: map-get($settings, 'link-hover-color');
    .frame-background-#{$name},
    .bg-#{$name} {
        color: $color;
        background-color: $background;

        svg {
            fill: $color;
        }

        a:not(.btn) {
            color: $link-color;
            &:focus,
            &:hover {
                color: $link-hover-color;
            }
        }
    }

    .frame-background-#{$name} {
        // Frames with background get paddings
        @each $breakpoint in map-keys($frame-spacing-scaling) {
            @include media-breakpoint-up($breakpoint) {
                $scaling: map-get($frame-spacing-scaling, $breakpoint);
                margin-top: 0;
                margin-bottom: 0;
                padding-top: ($frame-spacing * $scaling);
                padding-bottom: ($frame-spacing * $scaling);
            }
        }
    }
}

@each $name, $settings in $frame-backgrounds {
    @include make-frame-background($name, $settings);
}

.frame-background-hatched {
    background: $light url('../../Images/hatched.jpg') repeat;
}

// - Space
.frame-space-before-large {
    &.frame-background-none {
        margin-top: 6.75rem;

        @include media-breakpoint-up(lg) {
            margin-top: 9.75rem;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 11.25rem;
        }
    }

    @each $name, $settings in $frame-backgrounds {
        &.frame-background-#{$name} {
            padding-top: 6.75rem;

            @include media-breakpoint-up(lg) {
                padding-top: 9.75rem;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 11.25rem;
            }
        }
    }
}


.frame-space-after-large {

    &.frame-background-none {
        margin-bottom: 6.75rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 9.75rem;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 11.25rem;
        }
    }


    @each $name, $settings in $frame-backgrounds {
        &.frame-background-#{$name} {
            padding-bottom: 6.75rem;

            @include media-breakpoint-up(lg) {
                padding-bottom: 9.75rem;
            }

            @include media-breakpoint-up(xl) {
                padding-bottom: 11.25rem;
            }
        }
    }
}

.frame-space-before-small {

    &.frame-background-none {
        margin-top: 2.25rem;

        @include media-breakpoint-up(lg) {
            margin-top: 3rem;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 3.75rem;
        }
    }

    @each $name, $settings in $frame-backgrounds {
        &.frame-background-#{$name} {
            padding-top: 2.25rem;

            @include media-breakpoint-up(lg) {
                padding-top: 3rem;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 3.75rem;
            }
        }
    }
}

.frame-space-after-small {

    &.frame-background-none {
        margin-bottom: 2.25rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 3rem;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 3.75rem;
        }
    }

    @each $name, $settings in $frame-backgrounds {
        &.frame-background-#{$name} {
            padding-bottom: 2.25rem;

            @include media-breakpoint-up(lg) {
                padding-bottom: 3rem;
            }

            @include media-breakpoint-up(xl) {
                padding-bottom: 3.75rem;
            }
        }
    }
}

.frame-space-before-none {
    margin-top: 0;
    padding-top: 0;
}

.frame-space-after-none {
    margin-bottom: 0;
    padding-bottom: 0;
}

.frame-grid-columns {
    .frame {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;

        p {
            width: 100% !important;
        }
    }

    @include media-breakpoint-down(md) {
        &.xs-space {
            > .row {
                .col-12 {
                    margin-bottom: 1.5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .frame-header {
        h1, h2 {
            &.text-center {
               margin-left: auto;
               margin-right: auto;
            }
        }
    }

    .textmedia-right,
    .textmedia-left,
    .textpic-right,
    .textpic-left,
    .frame-grid-columns {
        .frame-header {
            h1, h2 {
                width: 100%;
                max-width: none;
            }
        }
    }
}

.frame-type-text .frame-inner,
.frame-type-text .frame-inner .news-detail-lead,
.frame-type-text .frame-inner .news-detail-text,
.frame-type-textpic .textpic-above .textpic-text,
.frame-type-textpic .textpic-below .textpic-text,
.frame-type-textmedia .textmedia-above .textmedia-text,
.frame-type-textmedia .textmedia-below .textmedia-text {
    > p,
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > ol,
    > ul {
        @include media-breakpoint-up(lg) {
            width: 83.33333%;
            max-width: 60rem;

            @if($env == 'adm') {
                margin-right: auto;
                margin-left: auto;
            }

            &.text-center {
                margin-left: auto;
                margin-right: auto;
            }
        }

        @include media-breakpoint-up(xl) {
            width: 66.66667%;

            @if($env == 'adm') {
                margin-right: auto;
                margin-left: auto;
            }


        }
    }

    > header {
        margin: 0 auto;

        @if($env == 'adm') {
            @include media-breakpoint-up(lg) {
                width: 83.33333%;
                max-width: 60rem;

                &.text-center {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            @include media-breakpoint-up(xl) {
                width: 66.66667%;
                margin: 0 auto;
            }
        }
    }


}


.frame-grid-columns {
    .frame-type-text .frame-inner,
    .frame-type-text .frame-inner .news-detail-lead,
    .frame-type-text .frame-inner .news-detail-text,
    .frame-type-textpic .textpic-above .textpic-text,
    .frame-type-textpic .textpic-below .textpic-text,
    .frame-type-textmedia .textmedia-above .textmedia-text,
    .frame-type-textmedia .textmedia-below .textmedia-text {
        > p,
        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > ol,
        > ul {
            @include media-breakpoint-up(lg) {
                width: 100% !important;

                &.text-center {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        > header {
            margin: 0 auto;

            @if($env == 'adm') {
                @include media-breakpoint-up(lg) {
                    width: 100% !important;

                    &.text-center {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }


    }
}


.frame-grid-columns header {
    width: 100% !important;
}

@if($env == 'adm') {
    @include media-breakpoint-up(lg) {
        .frame-header h1 {
            line-height: 4.2rem;
        }
    }

    .frame-header h3 {
        line-height: 2.3rem;
    }

    /*@include media-breakpoint-down(md) {
        .frame-grid-columns .col-12 {
            margin-bottom: 2rem;
        }
    }*/
}

