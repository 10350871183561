$env: "adm";
@import "variables";

//IconFont
@import "iconfont";

//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";

$colors: map-remove($colors, "indigo", "purple", "pink", "red", "orange", "yellow", "green", "teal", "cyan", "gray", "gray-dark");
$theme-colors: map-remove($theme-colors, "primary", "secondary", "success", "info", "warning", "danger", "light", "dark");


//Bootstrap Optional
//@import "../../Contrib/Bootstrap/scss/type" --> custom;
@import "../../Contrib/Bootstrap/scss/images";
//@import "../../Contrib/Bootstrap/scss/code";
//@import "../../Contrib/Bootstrap/scss/grid";
@import "../../Contrib/Bootstrap/scss/tables";
//@import "../../Contrib/Bootstrap/scss/buttons"; --> custom
@import "../../Contrib/Bootstrap/scss/transitions";
@import "../../Contrib/Bootstrap/scss/dropdown";
//@import "../../Contrib/Bootstrap/scss/button-group";
//@import "../../Contrib/Bootstrap/scss/input-group";
@import "../../Contrib/Bootstrap/scss/forms";
//@import "../../Contrib/Bootstrap/scss/custom-forms";
@import "../../Contrib/Bootstrap/scss/nav";

//@import "../../Contrib/Bootstrap/scss/navbar";
//@import "../../Contrib/Bootstrap/scss/card";
@import "../../Contrib/Bootstrap/scss/breadcrumb";
//@import "../../Contrib/Bootstrap/scss/pagination";
//@import "../../Contrib/Bootstrap/scss/badge";
//@import "../../Contrib/Bootstrap/scss/jumbotron";
//@import "../../Contrib/Bootstrap/scss/alert";
//@import "../../Contrib/Bootstrap/scss/progress";
//@import "../../Contrib/Bootstrap/scss/media";
@import "../../Contrib/Bootstrap/scss/list-group";
//@import "../../Contrib/Bootstrap/scss/close";
//@import "../../Contrib/Bootstrap/scss/toasts";
@import "../../Contrib/Bootstrap/scss/modal";
//@import "../../Contrib/Bootstrap/scss/tooltip";
//@import "../../Contrib/Bootstrap/scss/popover";
//@import "../../Contrib/Bootstrap/scss/carousel";
//@import "../../Contrib/Bootstrap/scss/spinners";
//@import "../../Contrib/Bootstrap/scss/utilities";
@import "../../Contrib/Bootstrap/scss/print";

// Custom Styles
@import "navbar";
@import "bsbuttons";
@import "bsutilities";
@import "dpcookieconsent";
@import "frame";
@import "textpic";
@import "gallery";
@import "magnific";
@import "boxes";
@import "infoteaser";
@import "selftest";

// Text selection
::selection {
  background: $selection-bg; /* WebKit/Blink Browsers */
  color: $selection-color;
}

::-moz-selection {
  background: $selection-bg; /* Gecko Browsers */
  color: $selection-color;
}


ul {
  list-style: none;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item) {
  position: relative;
  line-height: 1.563rem;
  padding-left: 10px;
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0;
  }
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item)::before {
  content: "\2022";
  color: $bullet-color;
  font-weight: 700;
  font-size: 2.5rem;
  display: inline-block;
  width: 1rem;
  left: 0;
  margin-left: -1rem;
  position: absolute;

  @if ($env == 'adm') {
    top: -5px;
  }
}

// Skiplinks
.skiplinks {
  .sr-only {
    &:focus {
      display: inline-block;
      padding: 0.5rem;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal;
      background-color: $white;
      z-index: $zindex-skiplinks;
    }
  }
}

.header-caption {
  &.animated {
    animation: fadeInHeader 1.4s linear 0.5s forwards;
  }
}

// Layout
.container {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

//News List View
.news-single {

  .elements-wrapper {
    .frame {
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }

  .news-detail-lead,
  .news-detail-gallery {
    margin-top: 2rem;
  }

  .news-detail-text {
    margin-top: 1.5rem;
  }

}

//Downloadelemente
.frame-type-uploads {
  .media-list {
    .media {
      list-style-type: none;
      padding-left: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 0 !important;

      .uploads-fileicon {
        margin-right: 10px;
      }

      a {
        color: $body-color;
        text-decoration: none;

        &:hover {
          color: $primary;
          text-decoration: underline;

          .uploads-fileicon {
            svg {
              fill: $primary;
            }
          }
        }
      }

      &::before {
        display: none !important;
      }
    }
  }
}

// Footer
.footer {
  @if ($env == 'adm') {
    font-weight: 300;

    .footer-content.bg-white {
      position: relative;
      background-color: $white;
      color: $gray-333;
      padding-top: 1.25rem;
      padding-bottom: 1.5rem;
      font-size: 0.813rem;
      line-height: 1.125rem;

      @media screen and (min-width: 450px) {
        padding-bottom: 0;
        height: 220px;
      }

      @include media-breakpoint-up(md) {
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
      }

      @include media-breakpoint-up(xl) {
        padding-top: 0.313rem;
        padding-bottom: 0.313rem;
      }
    }

    .footer-content.bg-dark {
      background-color: $primary;
    }

    .footer-content.bg-gray {
      background-color: $gray-f3;
      color: $gray-333;
      padding-top: 1.875rem;
      padding-bottom: 1.875rem;

      @include media-breakpoint-up(xl) {
        padding-top: 0.938rem;
        padding-bottom: 0.938rem;
      }

      a.text-white {
        color: $gray-333 !important;
        margin-left: 15px;
        margin-right: 15px;
      }

      li:first-of-type a.text-white {
        margin-left: 0;
      }

      li:last-of-type a.text-white {
        margin-left: 0;
      }

      .list-unstyled {
        margin-bottom: 0;
      }
    }

    .w4-adress-1 {
      margin-bottom: 2.813rem;
      margin-top: 0;
    }

    .w4-spielerschutz-1 {
      align-self: flex-start;

      @include media-breakpoint-up(md) {
        align-self: center;
      }
    }

    .w4-spielerschutz-3 {
      position: absolute;
      top: 120px;

      @include media-breakpoint-up(md) {
        position: initial;
      }

      img {
        margin-right: 3px;

        @media screen and (min-width: 410px) {
          margin-right: 15px;
        }
      }
    }
  }

  .footer-content {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @if ($env == 'adm') {
      padding-top: 3.125rem;
      padding-bottom: 3.125rem;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 7rem;
      padding-bottom: 7rem;

      @if ($env == 'adm') {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }
    }

    @include media-breakpoint-down(lg) {
      .footer-address {
        width: 250px;
      }
    }
  }

  .h2 {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 600px;
    }

    &:after {
      border-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .footer-partners {
      width: 600px;
    }
  }

  &-nav {
    @if ($env == 'adm') {
      text-align: center;
      font-size: 0.875rem;
    }

    @include media-breakpoint-up(lg) {
      width: 600px;

      @if ($env == 'adm') {
        width: auto;
      }
    }

    .list-item + .list-item {
      margin-top: 0.437rem;
      margin-bottom: 0.437rem;
    }

    li {
      display: inline;

      @if ($env == 'adm') {
        display: inline-block;
      }
    }
  }

  .footer-social {
    margin-top: 1.625rem;
    margin-bottom: 1.875rem !important;

    @if ($env == 'adm') {
      margin-top: 1.875rem;
      margin-bottom: 2.5rem !important;

      @include media-breakpoint-up(lg) {
        margin-top: 2.375rem;
      }
    }
  }

  .footer-facebook,
  .footer-instagram,
  .footer-twitter,
  .footer-youtube {
    text-indent: -9999px;
    display: inline-block;
    height: 30px;
    width: 30px;
  }

  .footer-facebook {
    background: url("../../Icons/Theme/facebook.svg") no-repeat;
  }

  .footer-instagram {
    background: url("../../Icons/Theme/instagram.svg") no-repeat;
    margin-left: 15px;
  }

  @if ($env == 'adm') {
    .footer-facebook,
    .footer-instagram,
    .footer-twitter,
    .footer-youtube {
      height: 40px;
      width: 40px;
      background-size: contain !important;

      @include media-breakpoint-up(xl) {
        width: 44px;
        height: 44px;
      }

    }

    .footer-facebook {
      background: url("../../Icons/Theme/adm-facebook.svg") no-repeat;
    }

    .footer-instagram {
      background: url("../../Icons/Theme/adm-instagram.svg") no-repeat;
      margin-left: 15px;
    }
    .footer-twitter {
      background: url("../../Icons/Theme/adm-twitter.svg") no-repeat;
      margin-left: 15px;
    }
    .footer-youtube {
      background: url("../../Icons/Theme/adm-youtube.svg") no-repeat;
      margin-left: 15px;
    }
  }
}

@include media-breakpoint-down('xs') {
  .footer {
    &-map {
      margin-left: -$grid-gutter-width/2;
      margin-right: -$grid-gutter-width/2;
    }
  }
}

.figure-caption {
  margin-top: 0.25rem;
  text-align: center;
}

@include media-breakpoint-up(md) {
  .frame-default.off-screen {
    .frame-inner {
      transition: 0.8s ease;
      opacity: 0;
      transform: translate(0, 120px);
    }

    &.animated {
      .frame-inner {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
}

@keyframes bounceScrollDown {
  0%, 100% {
    transform: translateX(0);
  }
  44% {
    transform: translateX(-12px);
  }
}

@keyframes bounceRight {
  0%, 100% {
    transform: translateX(0);
  }
  56% {
    transform: translateX(12px);
  }
}

@keyframes fadeInHeader {
  0% {
    opacity: 0;
    transform: translate(1.5rem, 0.5rem);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@if ($env == 'adm') {
  ul li,
  ol li {
    font-size: $font-size-base;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base-xl;
    }
  }
}

//ADM FORM LOGIN
@if ($env == 'adm') {
  .frame-type-felogin_login .frame-inner {
    margin-left: auto;
    margin-right: auto;

    h3,
    p {
      text-align: center;
    }

    .form-control {
      border-color: $darkblue;
    }

    .form-control:focus {
      box-shadow: none;
      outline: 2px solid $yellow;
    }

    @include media-breakpoint-up(lg) {
      width: 83.33333%;
      max-width: 60rem;
    }

    @include media-breakpoint-up(xl) {
      width: 66.66667%;

    }
  }
  .adm-login label,
  .layout_form label {
    font-weight: bold;
    font-size: 1.25rem;
  }

  .layout_form .cr_body {
    margin-left: auto;
    margin-right: auto;
  }
}

//END ADM FORM LOGIN
//LOCATION SNIPPET
@if ($env == 'adm') {
  @media screen and (min-width:768px) {
    #store-finder-widget .ubsf_locations-list-item .ubsf_locations-list-item-additional-info li:before {
      margin-left: initial;
    }

    #store-finder-widget ul.ubsf_details-services-list li::before,
    #store-finder-widget ul.ubsf_details-languages-list li::before,
    #store-finder-widget ul.ubsf_details-social-profiles-list li::before,
    #store-finder-widget ul.ubsf_sitemap-list li::before,
    #store-finder-widget ul.ubsf_sitemap-grouped-locations li::before {
      display: none;
    }


    .page-wrapper #store-finder-widget .ubsf_outer-wrap {
      margin-bottom: 77px;
      padding-top: 3rem;
    }
    #store-finder-widget .ubsf_info-window-content-info li:before {
      left: -15px;
    }


    .page-wrapper #store-finder-widget .ubsf_store-finder-wrapper.main {
      position: relative;
      max-width: none;
    }

    .page-wrapper #store-finder-widget .ubsf_main-page-content {
      padding-top: 50px;
    }

    .page-wrapper #store-finder-widget .ubsf_storefinder-search-box {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: 400px;
      max-width: 100%;
      z-index: 99;
    }

    .page-wrapper #store-finder-widget .ubsf_auto-complete-overlay {
      display: none;
    }

    .page-wrapper #store-finder-widget .ubsf_main-page-content-inner {
      margin-left: 0;


      @media screen and (max-width: 991.999px) {
        min-height: 92.5vh;
      }
    }

    .page-wrapper #store-finder-widget .ubsf_main-page-content-filters-wrapper {
      width: 0;
    }

    .page-wrapper #store-finder-widget .ubsf_store-finder-column.ubsf_store-finder-map-column.ubsf_map-hidden {
      pointer-events: all;
      opacity: 1;
    }

    .page-wrapper #store-finder-widget .ubsf_filter-box, .page-1 #store-finder-widget .ubsf_locations-list {
      display: none;
    }

    .page-wrapper #store-finder-widget .ubsf_store-finder-column.ubsf_store-finder-map-column {
      max-width: none;
    }
  }
}

/* LS Cookie Consent */
.tx-ls-cookie-consent {

  h3 {
    margin-bottom: 1.5rem;
  }

  .c-p-table {
    table {
      thead {
        th {
          background-color: $gray-400;
          padding: 10px;
          border-left: $gray-700 solid 1px;
          text-align: center;

          &:first-child {
            border-left: none;
          }
        }
      }

      tbody {
        .c-p-table__group {
          background-color: $gray-200;
          margin-bottom: 5px;
        }

        tr {
          &:not(.c-p-table__group) {
            border-bottom: $gray-700 solid 1px;
          }
        }

        td {
          padding: 5px;
        }
      }
    }
  }
}

.cookie-overlay {
  z-index: 10000;
  background-color: rgba($dark, 0.5);
  text-align: center;
  padding: 20px;

  //.bodytext {
  //  overflow: scroll;
  //  max-height: calc(100% - 3rem);
  //}

  &.cookie-overlay-youtube {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
